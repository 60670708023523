<template>
  <ul class="home-list-ul" @click="open_paper(data.id)">
    <li class="home-list-li">
      <div class="list-left">
        <div class="list-left-img">
          <img v-if="data.canvasthumb" :src="data.canvasthumb" alt="" />
        </div>
        <div class="list-left-name">
          <div class="list-left-n">
            {{ data.name }}
            <i
              class="el-icon-star-on"
              @click.stop="send('store')"
              :id="data.collectionFlag == 1 ? 'active' : ''"
            ></i>

            <span class="list-left-open" v-if="!data.openFlag">
              <i class="iconfont icon-Lock"></i>
              非公开
            </span>
          </div>
          <div class="list-left-edit">修改于{{ data.updateDate | time }}</div>
        </div>
      </div>
      <div class="list-right">
        <el-tooltip
          class="item"
          effect="dark"
          content="设置"
          placement="top"
          :id="!data.contextmenu ? 'none' : ''"
        >
          <div class="set-more" @click.stop="send('set', $event)">
            <i class="el-icon-more"></i>
          </div>
        </el-tooltip>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "paperCell",
  props: ["data"],
  methods: {
    splitMst(list) {
      return list.slice(0, 4);
    },
    send(value, e = {}) {
      this.$emit("listionEvent", { event: value, value: this.data, e: e });
    },
    open_paper() {
      window.open(
        `/paper/${this.data.sourceId || this.data.id}`,
        "_blank",
        "noopener"
      );
      // this.$router.push({
      //   name: "Paper",
      //   params: { id: this.data.sourceId || this.data.id }
      // });
    }
  }
};
</script>

<style lang="scss" scoped>
#active {
  color: #f7bc03;
  opacity: 0.7;
}
.el-icon-star-on {
  color: #98bbd4;
  font-size: 18px;
  transition: all 0.2s;
  opacity: 0;
  margin: 0 2px;
  &:hover {
    color: #373839;
  }
}
.home-list-ul {
  margin: 1px;
}
ul .home-list-li {
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px #e2e2e2;
  background-color: #fff;
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.list-left {
  display: flex;
  align-items: center;
  &.left-no-img {
    margin-left: 20px;
  }
  .icon-folder {
    color: #f7bc03;
    font-size: 40px;
  }
  .list-left-name {
    padding-left: 10px;
    font-size: 14px;
    color: #373839;
    .list-left-n {
      position: relative;
      top: -7px;
      display: flex;
      align-items: center;
      .list-left-open {
        padding-left: 8px;
      }
      img {
        width: 30px;
        height: 30px;
        display: inline-block;
      }
    }
    .list-left-edit {
      color: #8e959a;
      font-size: 13px;
      position: relative;
      top: -7px;
    }
  }
  .list-left-img {
    width: 100px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fbfbfc;
    border-right: 1px solid #e2e2e2;
    img {
      width: 75px;
      height: 56px;
    }
  }
}

.list-right {
  cursor: pointer;
  width: 24px;
  height: 24px;
  z-index: 30;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  display: inline-block;
  text-align: center;
  line-height: 24px;
  font-size: 18px;
  .el-icon-more {
    pointer-events: none;
  }
}
</style>
