<template>
  <div class="header-main-box">
    <div class="header-index-con">
      <div class="search-head-btn display-flex" @click="openSearchCtrl">
        <i class="iconfont icon-sousuo"></i>
        <span>搜索</span>
      </div>
      <div class="index-header-modules display-flex">
        <div class="header-modules-item">
          <el-button size="mini" @click="changeTheme">切换主题</el-button>
        </div>
        <div class="header-modules-item">
          <div class="system-notification" @click="xpaperMessage">
            <el-badge
              :value="noticeListPageInfo.unReadCount"
              :hidden="!(noticeListPageInfo.unReadCount > 0)"
              :max="99"
              class="item"
            >
              <i class="iconfont icon-tongzhi" :class="notiActive"></i>
            </el-badge>
          </div>
        </div>
        <div class="header-modules-item" v-if="xppUserInfo">
          <div class="user-profile-photo display-flex" @click="userCtrlOff">
            <div
              class="xpp-user-photo"
              :style="xppUserInfo.headImg | styleUserPhoto"
            ></div>
            <i class="iconfont icon-xiangxia"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerEvent from "@plugins/headerEvent";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "HomeHead",
  mixins: [headerEvent],
  computed: {
    ...mapGetters(["xppThemeState"])
  },
  methods: {
    showAc() {
      this.$refs.active.showActive();
    },
    changeTheme() {
      this.$UpdataVuexState({
        key: "xppThemeState",
        data: this.xppThemeState === 1 ? 0 : 1,
        local: true
      });
    },
    ...mapMutations(["$UpdataVuexState"])
  }
};
</script>

<style lang="scss" scoped>
.header-main-box {
  height: $header-height-default;
}

.header-index-con {
  height: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 0 #e9ebec;
}

.search-head-btn {
  color: #94989c;
  span {
    margin-left: 8px;
  }
}
</style>
