<template>
  <div class="invite-container">
    <el-dialog
      :visible.sync="dialog"
      width="640px"
      custom-class="invite"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div slot="title" :class="title.className" v-html="title.con"></div>
      <div class="dialog-content" v-if="creatLinkDialog === 0">
        <h3>邀请团队成员</h3>
        <div class="input-list xpp-scrollbar">
          <input
            type="text"
            placeholder="name@domain.com"
            class="email-input"
            v-for="(item, index) in list"
            :key="index"
            @blur="emailVerify"
            v-model="list[index]"
          />
        </div>
        <div @click="list.push('')" class="yaoqing x-cursor">
          <i class="el-icon-circle-plus-outline"></i>邀请更多
        </div>
        <div class="invite-content">
          <div class="linkIcon x-cursor" @click="getInviteLink">
            <i class="el-icon-link"></i>
            获取专属邀请链接
          </div>
          <div class="btn x-cursor" @click="setList">邀请</div>
        </div>
        <div class="footer">
          若超过当前套餐的人数上线，
          <span class="xufei">请续费套餐</span>
        </div>
      </div>
      <div class="invitelink-timelimit" v-if="creatLinkDialog === 1">
        <el-select v-model="timelimit" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button type="warning" @click="creatInvitelink"
          >生成邀请链接</el-button
        >
      </div>
      <div class="invitelink-container" v-if="creatLinkDialog === 2">
        <p class="text-center">专属邀请二维码</p>
        <div class="canvaslink-con">
          <canvas width="80" height="80" ref="invitelinkCanvas"></canvas>
        </div>
        <div>
          <p>Hiten团队专属邀请链接</p>
          <div class="link-form">
            <input
              type="text"
              readonly
              v-model="invitelinkInput"
              ref="xppShareLInkInput"
            />
            <el-button type="warning" @click="xppShareLinkCopy">复制</el-button>
          </div>
          <div class="link-explain">
            <span>1 天内有效。 需要修改？您可以重置邀请链接</span>
            <button @click="getInviteLink">重置</button>
          </div>
        </div>
      </div>
      <div class="invitelink-end" v-if="creatLinkDialog === 3">
        <ul>
          <li
            class="display-flex"
            v-for="(item, index) in inviteList"
            :key="index"
          >
            <span class="text-overflow">
              <i
                :class="{
                  'el-icon-check': item.state,
                  'el-icon-close': !item.state
                }"
              ></i>
              {{ item.email }}
            </span>
            <b v-if="item.state">已向该邮箱发送了邀请</b>
            <b v-else>此用户已加入了其他团队</b>
          </li>
        </ul>
        <div class="display-flex flex-center" style="margin-top:30px;">
          <el-button style="width: 245px;" type="warning" @click="handleClose"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import QRCode from "qrcode";

export default {
  name: "invite",
  props: ["dialog"],
  computed: {
    title() {
      let tit = {};
      switch (this.creatLinkDialog) {
        case 0:
          tit.className = "title";
          tit.con = "发送邀请";
          break;
        case 1:
        case 2:
          tit.className = "title-link";
          tit.con = `<h2>邀请成员加入你的团队！</h2><p>您的成员可通过此链接加入 Hiten团队</p>`;
          break;
        case 3:
          tit.className = "title-link";
          tit.con = `<h2>你已邀请了${this.inviteList.length}名新成员</h2>`;
          break;
      }
      return tit;
    },
    memberList() {
      let list = {};
      for (let i = 0; i < this.xppTeamMemberList.length; i++) {
        list[this.xppTeamMemberList[i].userId] = true;
      }
      return list;
    },
    ...mapGetters(["xppUserInfo", "xppTeamMemberList"])
  },
  data() {
    return {
      emailVerifyRegExp: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
      list: [""],
      inviteList: [],
      invitelinkInput: "",
      creatLinkDialog: 0,
      timelimit: 86400,
      options: [
        {
          label: "1天内有效",
          value: 86400
        },
        {
          label: "7天内有效",
          value: 172800
        },
        {
          label: "30天内有效",
          value: 2592000
        }
      ]
    };
  },
  methods: {
    handleClose() {
      this.$emit("update:dialog", false);
      setTimeout(() => {
        this.creatLinkDialog = 0;
      }, 300);
    },
    emailVerify({ target }) {
      if (this.emailVerifyRegExp.test(target.value)) {
        target.style.borderColor = null;
      } else {
        target.style.borderColor = "red";
      }
    },
    async setList() {
      let list = this.list.filter(item => item.trim());
      if (!list.length) return this.$alert(`请输入被邀请人邮箱`);
      let err = [];
      for (let i = 0; i < list.length; i++) {
        if (!this.emailVerifyRegExp.test(list[i])) err.push(i + 1);
      }
      if (err.length) return this.$alert(`第${err.join()}条数据输入有误`);
      let { list: info } = await this.$axios.get("/dis/u/findbyEmail", {
        params: { email: list.join(",") }
      });
      this.inviteList = info.map(item => {
        let dd = { state: true, id: item.userId, email: item.email };
        if (this.memberList[item.userId]) dd.state = false;
        return dd;
      });
      let reciveuserIdList = this.inviteList
        .filter(item => item.state)
        .map(item => item.id);
      if (!reciveuserIdList.length) {
        this.creatLinkDialog = 3;
        return;
      }
      await this.$axios.post("/dis/team/invitation", {
        user_id: reciveuserIdList.join(),
        team_id: this.xppUserInfo.companyId
      });
      this.creatLinkDialog = 3;
    },
    getInviteLink() {
      this.creatLinkDialog = 1;
    },
    async creatInvitelink() {
      let {
        headImg,
        nickName,
        email,
        companyId,
        companyName
      } = this.xppUserInfo;
      let { info } = await this.$axios.post("/dis/team/geturl", {
        headImg,
        email: email,
        name: nickName,
        companyId,
        time: this.timelimit,
        companyName
      });
      this.invitelinkInput = `${location.origin}/t/join/${info}`;
      this.creatLinkDialog = 2;
      this.$nextTick(() => {
        QRCode.toCanvas(this.$refs.invitelinkCanvas, this.invitelinkInput, {
          margin: 0,
          width: 80,
          height: 80
        });
      });
    },
    // 复制分享链接
    xppShareLinkCopy() {
      this.$refs.xppShareLInkInput.select();
      document.execCommand("Copy");
      this.$message({
        message: "链接已复制到剪贴板",
        type: "success",
        duration: 1000
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: 32px;
    color: #373839;
    margin: 0 0 30px;
  }
  .email-input {
    width: 100%;
    height: 48px;
    border-radius: 3px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border: solid 1px #dadee2;
    margin-bottom: 8px;
    font-size: 17px;
    text-indent: 1em;
  }
  .yaoqing {
    width: 100%;
    color: #e49a22;
    line-height: 17px;
  }
  .footer {
    font-size: 12px;
    line-height: 20px;
  }
  .xufei {
    color: #e49a22;
    cursor: pointer;
  }
}
::v-deep {
  .invite {
    .title {
      font-size: 14px;
      color: #e49a22;
      line-height: 17px;
      margin-bottom: 2px;
      font-weight: 400;
      text-align: center;
      padding-top: 35px;
      background: url("/img/send_email_icon.svg") top center/32px 23px no-repeat;
    }
    .title-link {
      color: #373839;
      text-align: center;
      padding-top: 10px;
      h2 {
        font-size: 25px;
        margin-bottom: 10px;
      }
      p {
        font-size: 12px;
      }
    }
    .el-dialog__header {
      padding-top: 30px;
    }
    .el-dialog__body {
      padding: 0 50px 30px;
    }
    .el-icon-check,
    .el-icon-close:not(.el-dialog__close) {
      display: inline-block;
      width: 16px;
      height: 16px;
      text-align: center;
      line-height: 16px;
      border-radius: 100%;
      overflow: hidden;
      color: #fff;
    }
    .el-icon-check {
      background-color: #58ce81;
    }
    .el-icon-close:not(.el-dialog__close) {
      background-color: red;
    }
  }
}
.input-list {
  width: 100%;
  max-height: 300px;
}
input:not([disabled]):focus,
select:not([disabled]):focus,
textarea:not([disabled]):focus {
  background-color: #fff;
  border-color: #f5a623;
  z-index: 2;
}
.invite-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  border-bottom: 2px solid #eeeeee;
  padding-bottom: 15px;

  .linkIcon {
    display: flex;
    align-items: center;
    transition: color 0.3s;
    i {
      display: inline-flex;
      width: 40px;
      height: 40px;
      background-color: #6e7788;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin-right: 5px;
      font-size: 22px;
      color: #fff;
      transition: background-color 0.3s;
    }
    &:hover {
      color: #e49a22;
      i {
        background-color: #e49a22;
      }
    }
  }
  div {
    width: 100%;
  }
  .btn {
    border-radius: 4px;
    height: 48px;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5a623;
    color: #fff;
    &:hover {
      background-color: #e49a22;
    }
  }
}
.invitelink-timelimit {
  display: flex;
  margin-top: 50px;
  .el-select {
    flex: auto;
  }
  .el-button {
    flex: none;
    margin-left: 10px;
  }
}
.invitelink-container {
  p {
    line-height: 14px;
    font-size: 14px;
    font-weight: 600;
    color: #cf8d1f;
  }
  .canvaslink-con {
    width: 100px;
    height: 100px;
    margin: 15px auto 50px;
    border-radius: 4px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    canvas {
      flex: none;
    }
  }
  .link-form {
    margin-top: 15px;
    margin-bottom: 15px;
    [type="text"] {
      width: 430px;
      background-color: #fff;
      border-radius: 4px;
      color: #6f7075;
      border: none;
      box-shadow: 0 0 0 1px #d7d7d7;
      padding: 7px 14px;
      font-weight: 500;
      font-size: 14px;
      height: 26px;
      line-height: 26px;
    }
    .el-button {
      margin-left: 10px;
    }
  }
  .link-explain {
    span {
      font-size: 12px;
    }
    button {
      background: none;
      border: none;
      height: 12px;
      line-height: 12px;
      font-size: 12px;
      color: #cf8d1f;
      cursor: pointer;
    }
  }
}
.invitelink-end {
  padding: 0 60px;
  p {
    height: 18px;
    line-height: 18px;
  }
  i {
    vertical-align: middle;
    margin-right: 5px;
  }
  b {
    font-size: 12px;
    font-weight: 400;
  }
  span {
    width: 220px;
  }
  li {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    white-space: nowrap;
    line-height: 15px;
    font-size: 12px;
  }
}
</style>
