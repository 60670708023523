<template>
  <div class="active">
    <h2 class="home-favorites-tit title">活动</h2>
    <div
      class="home-favorites-page xpp-scrollbar"
      style="height: 90%; overflow: scroll;"
    >
      <div class="data">
        <ul v-for="(a, b) in list" :key="b" v-infinite-scroll="load">
          <li class="titData">
            {{ getTime(new Date(a.createDate).getTime()) }}
          </li>
          <li class="lio">
            <div class="heads">
              <p><img src="/img/stars.png" alt="" />{{ a.userName }}</p>
              <span>{{ a.createDate }}</span>
            </div>
            <ul v-for="(i, j) in a.list" :key="j">
              <li class="updata">
                <a href="#"
                  >更新
                  <span>
                    <b>{{ i.PAPER.count }}</b></span
                  >
                  项目
                  <span>
                    <b>({{ i.paperName }})</b></span
                  >
                </a>
              </li>
              <li>
                <ul v-for="(i, j) in a.list" :key="j">
                  <li v-if="i.COMMENT.count != 0">
                    添加<span>{{ i.COMMENT.count }}</span
                    >评论<span>{{ i.papername }}</span>
                  </li>
                  <li
                    class="comments"
                    v-for="(item, index) in i.COMMENT.data"
                    :key="index"
                  >
                    {{ item }}
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import timeago from "../../method/date";
import { mapGetters } from "vuex";
export default {
  name: "active",
  computed: {
    ...mapGetters(["xppCompanyId"])
  },
  data() {
    return {
      list: []
    };
  },
  methods: {
    getlist() {
      this.$axios
        .get("/mo/active/list", {
          params: { companyId: this.xppCompanyId, page: 1 }
        })
        .then(res => {
          this.list = res.list;
        });
    },
    load() {
      // this.list += 2
    },
    getTime(time) {
      return timeago(time);
    }
  },
  mounted() {
    this.getlist();
  }
};
</script>

<style lang="scss" scoped>
.active {
  height: 100vh;
}
.home-favorites-page {
  padding: 26px 32px;
}
.home-favorites-tit {
  margin-bottom: 16px;
}

.lio {
  padding: 5px 20px 16px;
  border: 1px solid #dbdee6;
  border-radius: 4px;
  background-color: #f5f6f9;
  margin: 10px 20px;
}
.lio .heads {
  display: block;
  margin-right: 0;
  margin-bottom: 8px;
  height: 24px;
  line-height: 24px;
}
.heads p {
  float: left;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  margin: 0 8px;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}
.heads span {
  float: right;
}
.heads p img {
  width: 24px;
  height: 24px;
}
.comments {
  width: auto;
  text-overflow: ellipsis;
  font-weight: 500;
  border-radius: 4px;
  background-color: #fff;
  border: solid 1px #dbdee6;
  padding: 6px 12px;
  margin-bottom: 3px;
}
.updata {
  margin-bottom: 10px;
  font-size: 13px;
}
.title {
  margin: 26px 0 26px 32px;
}
.titData {
  color: #8e959a;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  height: auto;
  display: inline-block;
}
</style>
