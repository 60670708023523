<template>
  <div
    class="projectRow home-project-item"
    @contextmenu.prevent="send('mouseenter')"
    @click.stop="openProject"
  >
    <div class="tran" v-if="data.collectionFlag == 1"></div>
    <div class="top">
      <div class="openflag" v-if="!data.openFlag">
        <i class="el-icon-lock"></i>非公开
      </div>
      <svg class="folder-icon " width="55px" height="64px" viewBox="0 0 64 64">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g :fill="data.colour" class="project-folder-color">
            <g transform="translate(0.000000, -1.000000)">
              <g transform="translate(0.000000, 6.000000)">
                <path
                  d="M25.6,0 L6.4,0 C2.864,0 0.032,2.90875 0.032,6.5 L0,45.5 C0,49.0912484 2.864,52 6.4,52 L57.6,52 C61.136,52 64,49.0912484 64,45.5 L64,13 C64,9.40875 61.136,6.5 57.6,6.5 L32,6.5 L25.6,0 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <div class="avatar-list">
        <el-tooltip
          class="item"
          v-for="item in membersList"
          :key="item.userId"
          effect="dark"
          :content="item.nickName"
          placement="top"
        >
          <div
            class="avatar xpp-user-photo"
            :style="item.headImg | styleUserPhoto"
          ></div>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="成员管理"
          placement="top"
        >
          <div class="avatar">
            {{ data.membersList.length }}
          </div>
        </el-tooltip>
      </div>
      <div
        class="set"
        v-if="data.contextmenu"
        @click.stop="send('set', $event)"
      >
        <el-tooltip class="item" effect="dark" content="设置" placement="top">
          <div class="set-more">
            <i class="el-icon-more"></i>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="bottom">
      <div class="title">
        {{ data.name
        }}<i
          v-if="data.contextmenu"
          class="el-icon-star-on"
          @click.stop="send('store')"
          :class="nodeVisibility"
        ></i>
      </div>
      <div class="time">
        {{ data.createDate | time }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "projectRow",
  props: ["data"],
  computed: {
    membersList() {
      return this.data.membersList.slice(0, 4);
    },
    nodeVisibility() {
      return {
        active: this.data.collectionFlag === 1
      };
    }
  },
  methods: {
    send(value, e = {}) {
      this.$emit("listionEvent", {
        event: value,
        value: this.data,
        e: { x: e.x - e.layerX, y: e.y - e.layerY + 28 }
      });
    },
    openProject({ target }) {
      if (target.classList.contains("set-more")) return;
      this.$router.push({
        name: "HomeProjectPaper",
        params: {
          id: this.data.sourceId || this.data.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.openflag {
  height: 24px;
  width: auto;
  line-height: 24px;
  display: flex;
  border-radius: 4px;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  padding: 0 5px;
  align-items: center;
  transition: background 0.2s;
  background-color: #fcfcfc;
  position: absolute;
  left: 13px;
  top: 13px;
}
.projectRow {
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.15s;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  box-shadow: 0 1px 5px rgba(50, 50, 50, 0.1);
  position: relative;
  .bottom {
    border-top: 1px solid #dedede;
    padding: 11px 13px 15px;
    transition: all 0.3s;
    &:hover {
      background: #fcfcfc;
    }
    &:hover .el-icon-star-on {
      opacity: 1;
    }
    .time {
      font-size: 12px;
      font-weight: 500;
      color: #8e959a;
      max-width: 180px;
      display: inline-block;
      margin-top: 5px;
      vertical-align: middle;
    }
    .el-icon-star-on {
      color: #8e959a;
      font-size: 18px;
      transition: all 0.2s;
      opacity: 0;
      margin: 0 2px;
      &:hover {
        color: #373839;
      }
      &.active {
        color: #f7bc03;
        opacity: 1;
      }
    }
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fcfcfc;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 15px;
  }
  .top .icon {
    font-size: 58px;
  }
  .set {
    position: absolute;
    right: 15px;
    top: 10px;
    border: 1px solid #dbdee6;
    border-radius: 6px;
    background: #fcfcfc;
    .set-more {
      padding: 3px 5px;
    }
    .el-icon-more {
      pointer-events: none;
    }
  }
  .avatar {
    width: 28px;
    height: 28px;
    margin-right: 8px;
    border: 1px solid #dbdee6;
    border-radius: 50%;
    background-color: #fcfcfc;
    transition: background 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #ccc;
    overflow: hidden;
    &:last-child:hover {
      background: #abb2b8;
      color: #fff;
    }
  }
  .avatar-list {
    margin-top: 10px;
    display: flex;
  }
}

.home-project-item {
  flex: none;
  margin-bottom: 32px;
  margin-right: 16px;
  min-width: 200px;
  max-width: 282px;
  width: calc(20% - 16px);
  box-sizing: border-box;
}
.icon-separator {
  display: inline-block;
  margin: 0 6px;
  width: 5px;
  height: 5px;
  background-color: #8e959a;
  border-radius: 100%;
  vertical-align: middle;
}

@media (max-width: 1720px) {
  .home-project-item {
    width: calc(25% - 16px);
  }
}

@media (max-width: 1500px) {
  .home-project-item {
    width: calc(33% - 16px);
  }
}

@media (max-width: 1280px) {
  .home-project-item {
    width: calc(50% - 16px);
  }
}
</style>
