<template>
  <div class="active xpp-scrollbar">
    <div class="home-favorites-page">
      <h2 class="home-favorites-tit">Hiten</h2>
      <div class="tabs">
        <div
          class="tabs-list"
          :id="active == 1 ? 'active' : ''"
          @click="setTab(1)"
        >
          管理成员
        </div>
        <div
          class="tabs-list"
          :id="active == 2 ? 'active' : ''"
          @click="setTab(2)"
        >
          团队设置
        </div>
        <div
          class="tabs-list"
          :id="active == 3 ? 'active' : ''"
          @click="setTab(3)"
        >
          付费
        </div>
      </div>
      <transition name="slide-fade">
        <div v-if="active == 1">
          <div class="team-member-invite-button" @click="dialog = true">
            邀请团队成员
          </div>
          <el-table :data="xppTeamMemberAllList" style="width: 100%">
            <el-table-column prop="date" label="昵称" width="280">
              <template slot-scope="scope">
                <div class="heads">
                  <div class="act-img">
                    <div
                      class="xpp-user-photo"
                      :style="scope.row.headImg | styleUserPhoto"
                    ></div>
                    <div class="img-status" v-if="scope.row.status === 1">
                      <span></span>
                    </div>
                  </div>
                  <div class="act-name">
                    <div>{{ scope.row.nickName }}</div>
                    <div>{{ scope.row.email }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="roleName"
              label="账户类型"
              width="200"
            ></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <div
                  class="status"
                  :class="scope.row.statusDesc !== '激活' ? 'frozen' : ''"
                ></div>
                {{ scope.row.statusDesc }}
              </template>
            </el-table-column>
            <el-table-column align="right">
              <template slot-scope="scope">
                <div class="right-more" v-if="xppUserInfo.id != scope.row.id">
                  <div
                    class="set-more"
                    @click.prevent="contentmenu($event, scope.row)"
                    @contextmenu.prevent="contentmenu($event, scope.row)"
                  >
                    <i class="el-icon-more"></i>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </transition>
      <transition name="slide-fade">
        <div v-if="active == 2">团队设置</div>
      </transition>
      <transition name="slide-fade">
        <div v-if="active == 3">付费</div>
      </transition>
    </div>
    <!-- //邀请人的框 -->
    <invite :dialog.sync="dialog"></invite>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import invite from "@/components/view/member/invite.vue";
export default {
  name: "member",
  computed: {
    ...mapGetters(["xppCompanyId", "xppUserInfo", "xppTeamMemberAllList"])
  },
  components: {
    invite
  },
  data() {
    return {
      active: 1,
      dialog: false
    };
  },
  methods: {
    setTab(index) {
      this.active = index;
    },
    contentmenu(event, row) {
      event.contextmenu = true;
      let items = [
        {
          label: "团队所有者",
          icon: "el-icon-user",
          onClick: () => {
            this.setRole(row, 1);
          }
        },
        {
          label: "团队成员",
          icon: "el-icon-user",
          onClick: () => {
            this.setRole(row, 2);
          }
        },
        {
          label: "管理员",
          icon: "el-icon-user",
          onClick: () => {
            this.setRole(row, 3);
          }
        },
        {
          label: "超级管理员",
          icon: "el-icon-user",
          onClick: () => {
            this.setRole(row, 13);
          }
        },
        {
          label: "冻结",
          icon: "el-icon-remove",
          onClick: () => {
            this.$axios
              .post("/dis/rmt/update", {
                id: row.id,
                status: row.status == 1 ? 2 : 1
              })
              .then(() => {
                this.$UpdataTeamMemberList(true);
              });
          }
        }
      ];
      if (row.status == 2) {
        items[items.length - 1].label = "激活";
      }
      if (row.roleId != 13) {
        items.splice(row.roleId - 1, 1);
      } else {
        items.splice(3, 1);
      }
      this.$contextmenu({
        items,
        event,
        //x: event.clientX,
        //y: event.clientY,
        customClass: "class-a",
        zIndex: 3,
        minWidth: 230
      });
      return false;
    },
    setRole(row, roleId) {
      this.$axios
        .post("/dis/rmt/update", {
          companyId: this.xppCompanyId,
          id: row.id,
          roleId: roleId
        })
        .then(() => {
          this.$UpdataTeamMemberList(true);
        });
    },
    ...mapActions(["$UpdataTeamMemberList"])
  }
};
</script>

<style lang="scss" scoped>
.active {
  width: 700px;
  height: calc(100vh - 100px);
  overflow-y: auto;
}
.home-favorites-page {
  height: 100%;
  margin-left: 40px;
  margin-top: 30px;
}
.home-favorites-tit {
  margin-bottom: 16px;
}
.col {
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: solid 3px #f5a623;
}
.active {
  .status {
    display: inline-block;
    background-color: #90ff01;
    width: 8px;
    height: 8px;
    margin: 0 10px 0 0;
    border-radius: 100%;
    &.frozen {
      background: red;
    }
  }
}
.heads {
  display: flex;
  align-items: center;
  .act-img {
    width: 32px;
    height: 32px;
    position: relative;
    .xpp-user-photo {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      float: left;
      margin-right: 5px;
      border: 1px solid #e2e2e2;
    }
    .img-status {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      z-index: 3;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
      top: 0;
      span {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #90ff01;
      }
      .frozen {
        background: red;
      }
    }
  }
  .act-name {
    margin-left: 10px;
  }
}

.tabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tabs-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-bottom: solid 3px #f0f0f0;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #676767;
  transition: border 0.2s ease;
}
.tabs #active {
  border-bottom: solid 3px #f5a623;
}
.team-member-invite-button {
  width: 126px;
  padding: 0 1.5em;
  margin: 10px 0 10px auto;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: #f5a623;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
// .slide-fade-leave-active {
//   transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
// }
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.right-more {
  width: 24px;
  height: 24px;
  z-index: 30;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  display: inline-block;
  text-align: center;
  line-height: 24px;
  .right-icon {
    font-size: 18px;
  }
}
</style>
