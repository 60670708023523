<template>
  <div class="home-project-list" @contextmenu.prevent="show($event)">
    <open
      :value="tmeplateObj"
      :menulist="menu"
      v-if="isright.type"
      :x="x"
      :y="y"
      class="index"
      @listionEvent="listionEvent"
    ></open>

    <div class="favorites-sort">
      <div class="f-select">
        <el-select placeholder="请选择" v-model="sortValue" @change="filter">
          <el-option
            v-for="item in sortOptions"
            :key="item.class"
            :label="item.text"
            :value="item.calss"
          >
          </el-option>
        </el-select>
      </div>
      <div class="f-icon" @click="tabSort('list')">
        <i
          class="el-icon-s-fold"
          :class="rowOrCell === 'list' ? '' : 'f-none-color'"
        ></i>
      </div>
      <div class="f-icon" @click="tabSort('block')">
        <i
          class="el-icon-menu"
          :class="rowOrCell === 'list' ? 'f-none-color' : ''"
        ></i>
      </div>
    </div>

    <!-- 模板 -->
    <template v-if="list.length">
      <div
        class="list-row home-list xpp-scrollbar"
        v-if="sort == 2 || sort == -2 || sort == 4 || sort == -4"
      >
        <!-- 横排 -->
        <div
          class="home-project-list"
          @contextmenu.prevent="show($event)"
          v-if="rowOrCell === 'block'"
        >
          <paperRow
            :data="item"
            v-for="(item, index) in filterList"
            @listionEvent="listionEvent"
            :key="index"
          ></paperRow>
        </div>
        <!-- 竖排 -->
        <div @contextmenu.prevent="show($event)" v-else>
          <paperCell
            :data="item"
            v-for="(item, index) in filterList"
            @listionEvent="listionEvent"
            :key="index"
          ></paperCell>
        </div>
      </div>

      <div
        class="list-row home-list xpp-scrollbar"
        v-if="sort == 1 || sort == -1"
      >
        <!-- 横排 -->
        <div v-for="(item, index) in filterList" :key="index">
          <div class="row-list-span-title">{{ item.updateDate }}</div>
          <div
            class="home-project-list"
            @contextmenu.prevent="show($event)"
            v-if="rowOrCell === 'block'"
          >
            <paperRow
              :data="item"
              v-for="(item, index) in item.option"
              :key="index"
              @listionEvent="listionEvent"
            ></paperRow>
          </div>
          <!-- 竖排 -->
          <div @contextmenu.prevent="show($event)" v-else>
            <paperCell
              :data="item"
              v-for="(item, index) in item.option"
              :key="index"
              @listionEvent="listionEvent"
            ></paperCell>
          </div>
        </div>
      </div>

      <div
        class="list-row home-list xpp-scrollbar"
        v-if="sort == 3 || sort == -3"
      >
        <!-- 横排 -->
        <div v-for="(item, index) in filterList" :key="index">
          <div class="row-list-span-title">
            <div
              class="xpp-user-photo"
              :style="item.headImg | styleUserPhoto"
            ></div>
            <span>{{ item.nickName }}</span>
          </div>
          <div
            class="home-project-list"
            @contextmenu.prevent="show($event)"
            v-if="rowOrCell === 'block'"
          >
            <paperRow
              :data="item"
              v-for="(item, index) in item.option"
              :key="index"
              @listionEvent="listionEvent"
            ></paperRow>
          </div>
          <!-- 竖排 -->
          <div @contextmenu.prevent="show($event)" v-else>
            <paperCell
              :data="item"
              v-for="(item, index) in item.option"
              :key="index"
              @listionEvent="listionEvent"
            ></paperCell>
          </div>
        </div>
      </div>

      <div
        class="list-row home-list xpp-scrollbar"
        v-if="sort == 5 || sort == -5"
      >
        <!-- 横排 -->
        <div v-for="(item, index) in filterList" :key="index">
          <div class="row-list-span-title">
            <i
              class="iconfont icon-folder"
              :style="{ color: item.colour, fontSize: '22px' }"
            ></i>
          </div>
          <div
            class="home-project-list"
            @contextmenu.prevent="show($event)"
            v-if="rowOrCell === 'block'"
          >
            <paperRow
              :data="item"
              v-for="(item, index) in item.option"
              :key="index"
              @listionEvent="listionEvent"
            ></paperRow>
          </div>
          <!-- 竖排 -->
          <div @contextmenu.prevent="show($event)" v-else>
            <paperCell
              :data="item"
              v-for="(item, index) in item.option"
              :key="index"
              @listionEvent="listionEvent"
            ></paperCell>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="paper-none-msg display-flex">
        <span>此项目下没有内容</span>
      </div>
    </template>
  </div>
</template>

<script>
import open from "@components/HomerightList";
import { mapGetters, mapMutations } from "vuex";
import paperRow from "@/components/view/paperRow";
import paperCell from "@/components/view/paperCell";
import qs from "qs";
export default {
  name: "HomeProjectPaper",
  components: {
    open,
    paperRow,
    paperCell
  },
  computed: {
    ...mapGetters([
      "xppCompanyId",
      "rowOrCell",
      "xppUserInfo",
      "xppProjectMemberInfo"
    ])
  },
  data() {
    return {
      sortValue: 1,
      menu: [],
      x: 0,
      y: 0,
      classType: 1,
      sortMenu: "list",
      sort: 2,
      tmeplateObj: {},
      filterList: [],
      pro: {},
      sortOptions: [
        {
          calss: 1,
          text: "最后修改日期"
        },
        {
          calss: 2,
          text: "名称"
        },
        {
          calss: 3,
          text: "所有者"
        },
        {
          calss: 4,
          text: "创建日期"
        }
      ],
      rightCss: {
        top: 30 + "px",
        left: 30 + "px",
        position: "fixed",
        "z-index": 999
      },
      isright: {
        type: false
      } //控制右键显示隐藏
    };
  },
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  methods: {
    /**
     * @description: 排序形式
     * @param {string} para list or blockoqu
     */
    tabSort(para) {
      this.$tagRowOrCell(para);
    },
    listionEvent(e) {
      switch (e.event) {
        case "soucang":
        case "store":
          this.stars(e.value);
          break;
        case "mouseenter":
          this.tmeplateObj = e.value;
          break;
        case "set":
          this.tmeplateObj = e.value;
          this.show(e.e, true);
          break;
      }
    },
    filter(v) {
      //为了不改变原数据 新加一个 filterList data数据在上放
      let sort = Math.abs(v);
      this.filterList = [
        ...this.$jok.sortFilter(this.list, {
          class: this.classType,
          sort: sort
        })
      ];
      this.sort = sort;
      localStorage.setItem("filter", this.sort);
    },

    show(ev) {
      this.isright.type = false;
      this.menu = [];
      let userRoleId = 4;
      if (ev.target && ev.target.className == "home-project-list") {
        return;
      }
      if (this.tmeplateObj.projectId) {
        switch (userRoleId) {
          case 4:
            this.menu = [4, 0, 5, 2, 10, 11, 12, 6];
            break;
          case 5:
          case 6:
          case 7:
            this.menu = [0, 5, 2, 10, 11];
            break;
          case false:
            this.menu = [];
            return;
        }
      } else {
        switch (userRoleId) {
          case 4:
            this.menu = [4, 5, 0, 1, 2, 6];
            break;
          case 6:
            this.menu = [4, 5, 0, 1, 2, 3];
            break;
          case 5:
          case 7:
            this.menu = [0, 1, 2, 3];
            break;
          case false:
            this.menu = [];
            return;
        }
      }

      if (window.openmenu) {
        window.openmenu.type = false;
      }
      this.$nextTick(() => {
        this.$set(this.isright, "type", true);
        window.openmenu = this.isright;
      });
      this.y = ev.y + "px";
      this.x = ev.x + "px";
    },
    open_paper(id) {
      window.open("/paper/" + id, "_blank", "noopener");
      // this.$router.push({ name: "Paper", params: { id: id } });
    },
    stars(obj) {
      if (obj.collectionFlag == 1) {
        obj.collectionFlag = 0;
      } else {
        obj.collectionFlag = 1;
      }
      let data = {
        companyId: this.xppUserInfo.companyId,
        sourceId: obj.id,
        sourceType: 2,
        collectionFlag: obj.collectionFlag
      };
      this.$axios.post("/dis/cl/collection", qs.stringify(data)).then(() => {
        if (obj.collectionFlag == 1) {
          this.$message("收藏成功");
        } else {
          this.$message("取消成功");
        }
      });
    },
    ...mapMutations(["$tagRowOrCell"])
  },
  watch: {
    list() {
      if (this.filterList.length < 1) {
        this.filterList = { ...this.list };
        this.sort = localStorage.getItem("filter") * 1 || 1;
        this.sortValue = Math.abs(localStorage.getItem("filter") * 1) || 1;
        this.filter(this.sort);
      } else {
        this.filterList = { ...this.list };
        this.filter(this.sort);
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.icon-shoucang1,
.icon-favorites-icon {
  font-size: 12px;
}
.home-list {
  width: 98%;
  > ul .home-list-li {
    width: 98%;
    padding-right: 20px;
    margin: 0 auto;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px #e2e2e2;
    background-color: #fff;
    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .list-left {
    display: flex;
    align-items: center;
    &.left-no-img {
      margin-left: 20px;
    }
    .icon-folder {
      color: #f7bc03;
      font-size: 40px;
    }
    .list-left-name {
      padding-left: 10px;
      font-size: 14px;
      color: #373839;
      .list-left-n {
        position: relative;
        top: -7px;
        display: flex;
        align-items: center;
        .list-left-open {
          padding-left: 8px;
        }
        img {
          width: 30px;
          height: 30px;
          display: inline-block;
        }
      }
      .list-left-edit {
        color: #8e959a;
        font-size: 13px;
        position: relative;
        top: -7px;
      }
    }
    .list-left-img {
      width: 100px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fbfbfc;
      border-right: 1px solid #e2e2e2;
      img {
        width: 75px;
        height: 56px;
      }
    }
  }

  .list-right {
    display: flex;
    align-items: center;
    > ul {
      display: flex;
      li {
        height: 26px;
        width: 26px;
        border: 1px solid #abb2b8;
        text-align: center;
        line-height: 26px;
        font-size: 12px;
        border-radius: 50%;
        margin-left: 10px;
      }
      .right-bg {
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        width: 26px;
        background-image: url(/img/profile_default.png);
        overflow: hidden;
      }
      padding-right: 20px;
    }
    .right-more {
      width: 24px;
      height: 24px;
      z-index: 30;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #e6e6e6;
      display: inline-block;
      text-align: center;
      line-height: 24px;
      .right-icon {
        font-size: 18px;
      }
    }
  }
}
.home-project-list {
  .home-project-item-main {
    display: block;
  }
}

.home-project-item {
  position: relative;
}

.paper-none-msg {
  width: 100%;
  justify-content: center;
  font-size: 14px;
  color: #333;
  padding: 30px;
}
.stars {
  width: 40px;
  height: 40px;
}
.stars img {
  width: 100%;
  height: 100%;
}

.favorites-sort {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 220px;
  top: 0px;
  right: 13px;
  .f-select {
    ::v-deep .el-input__inner {
      border: none;
      width: 150px;
      text-align: right;
    }
  }
  .f-icon {
    cursor: pointer;
    i {
      font-size: 20px;
    }
    ::v-deep .f-none-color {
      color: #ddd;
    }
  }
}
</style>
