<template>
  <div class="home-page">
    <div class="container-home">
      <Sidenav />
      <div class="container-main" v-if="refresh">
        <HomeHead />
        <transition name="fade" :duration="400">
          <router-view />
        </transition>
      </div>
    </div>
    <ProjectMemberManagement v-if="contextMenuType.type == 1" />
    <PaperMemberManagement v-else />
  </div>
</template>
<script>
import Product from "../product/Index";
import HomeHead from "@components/layout/HomeHead";
import Sidenav from "@components/layout/Sidenav";
import ProjectMemberManagement from "@components/ProjectMemberManagement";
import PaperMemberManagement from "@components/PaperMemberManagement";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "home",
  computed: {
    ...mapGetters(["contextMenuType", "xppUserInfo", "refresh"])
  },
  components: {
    HomeHead,
    Sidenav,
    ProjectMemberManagement,
    PaperMemberManagement
  },
  created() {
    console.log(333);
    document.title = `${this.xppUserInfo.nickName} | XCanvas`;
  },
  mounted() {
    this.wsInit();
  },
  methods: {
    wsInit() {
      this.$PaperWebSocket();
      this.$GetXppAllowanceInfo();
      this.$UpdataUserProjectList();
      this.$UpdataTeamMemberList();
    },
    ...mapActions([
      "$PaperWebSocket",
      "$GetXppAllowanceInfo",
      "$UpdataUserProjectList",
      "$UpdataTeamMemberList"
    ])
  }
};
</script>

<style lang="scss">
.container-home {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.container-main {
  width: calc(100vw - 208px);
  height: 100vh;
  overflow: hidden;
}

.home-project-list {
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  align-content: flex-start;
  .home-project-item-main {
    position: relative;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
    box-shadow: 0 1px 5px rgba(50, 50, 50, 0.1);
    overflow: hidden;
  }
}
.fade-enter-active,
.fade-leave-active {
  // transform: scale(1);
  width: 100%;
  height: 100%;
  transition: all 400ms;
  opacity: 1;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  // transform: scale(0.99);
  opacity: 0.1;
  // width: 95%;
  height: 100%;
  transition: all 400ms;
}
.home-project-item {
  flex: none;
  margin-bottom: 32px;
  margin-right: 16px;
  min-width: 200px;
  max-width: 282px;
  width: calc(25% - 16px);
}

@media (max-width: 1720px) {
  .home-project-item {
    width: calc(25% - 16px);
  }
}

@media (max-width: 1500px) {
  .home-project-item {
    width: calc(33% - 16px);
  }
}

@media (max-width: 1280px) {
  .home-project-item {
    width: calc(50% - 16px);
  }
}

.home-project-item-none:after {
  content: "";
  display: block;
  border: 1px solid #e2e2e2;
  flex: auto;
}

.home-project-item-head {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 13px;
  width: auto;
  pointer-events: none;
  .item-head-option {
    flex: none;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: #484d57;
    display: flex;
    align-items: center;
  }
  .iconfont {
    font-size: 14px;
  }
  span {
    margin-left: 5px;
  }
  .project-context-btn {
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    justify-content: center;
    cursor: pointer;
    pointer-events: auto;
  }
}
.home-project-item-con {
  height: 150px;
  background-color: #fcfcfc;
  border-bottom: 1px solid #e2e2e2;
  padding-top: 1px;
  .icon-folder {
    color: #f7bc03;
    font-size: 62px;
  }
}

.home-project-item-thumb {
  height: 134px;
  margin: 16px;
  margin-bottom: 0;
  background-color: #c3c3c3;
  background-size: contain;
}

.project-folder-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 10px;
}

.project-collaborator-container {
  justify-content: center;
}
.project-member-face {
  width: 26px;
  height: 26px;
  border-radius: 100%;
  background-color: #ddd;
  border: 1px solid #dbdee6;
  margin-left: 8px;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  &:last-child {
    border-color: #abb2b8;
    background-color: #fcfcfc;
    color: #abb2b8;
  }
}

.home-project-item-footer {
  padding: 11px 13px 15px;
  .home-project-item-tit {
    display: flex;
    align-items: center;
    font-size: 15px;
    height: 24px;
    line-height: 24px;
    color: #373839;
    .icon-shoucang1 {
      flex: none;
      margin-left: 6px;
      opacity: 0;
      pointer-events: none;
      cursor: pointer;
    }
    .icon-shoucang1 {
      color: #f0c78a;
    }
  }
  .home-project-item-time {
    font-size: 12px;
    font-weight: 500;
    color: #8e959a;
    max-width: 180px;
    padding-top: 2px;
  }
}

.creatuser-info-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .xpp-user-photo {
    width: 24px;
    height: 24px;
    background-color: #ddd;
    margin-right: 8px;
  }
}

.home-project-item-tit:hover {
  .icon-shoucang1 {
    opacity: 1;
    pointer-events: auto;
  }
}
.home-list {
  padding-left: 1px;
}
.home-list-li {
  .icon-shoucang1 {
    opacity: 0;
  }
}
.home-list-li:hover {
  .icon-shoucang1 {
    opacity: 1;
    pointer-events: auto;
  }
}

.row-list-span-title {
  position: sticky;
  top: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.2px;
  color: #777;
  padding: 5px 0;
  font-weight: 600;
  margin: 10px 0;
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 9;
  padding-right: 5px;
  margin-left: -3px;
  padding-left: 5px;
  .xpp-user-photo {
    width: 28px;
    height: 28px;
    background-color: #777;
    margin-right: 10px;
  }
}
</style>
