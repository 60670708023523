<template>
  <div class="box">
    <div class="home-project-list">
      <div class="right">
        <p>体验手机版</p>
        <div></div>
        <div></div>
      </div>
      <div class="favorites-menu">
        <el-tabs
          class="xpp-tabs-con"
          v-model="classType"
          @tab-click="handleClick"
          style="color:#8e959a"
          :stretch="true"
        >
          <el-tab-pane label="全部" name="1"></el-tab-pane>
          <el-tab-pane label="已加入" name="2"></el-tab-pane>
          <el-tab-pane label="未加入" name="3"></el-tab-pane>
        </el-tabs>
        <div class="favorites-sort">
          <div class="f-select">
            <el-select v-model="sortValue" placeholder="请选择">
              <el-option
                v-for="(item, index) in sortOptions"
                :key="item.class"
                :label="item.text"
                :value="item.calss"
                @click.native="filter(index + 1)"
              >
              </el-option>
            </el-select>
          </div>
          <div class="f-icon" @click="tabSort('list')">
            <i
              class="el-icon-s-fold"
              :class="rowOrCell === 'list' ? '' : 'f-none-color'"
            ></i>
          </div>
          <div class="f-icon" @click="tabSort('block')">
            <i
              class="el-icon-menu"
              :class="rowOrCell === 'list' ? 'f-none-color' : ''"
            ></i>
          </div>
        </div>
      </div>
      <open
        :value="tmeplateObj"
        :menulist="menu"
        v-if="isright.type"
        :x="x"
        :y="y"
        class="index"
        @listionEvent="listionEvent"
      ></open>

      <div
        class="list-row home-list xpp-scrollbar"
        v-if="sort == 2 || sort == -2 || sort == 4 || sort == -4"
      >
        <!-- 横排 -->
        <div
          class="home-project-list"
          @contextmenu.prevent="show($event)"
          v-if="rowOrCell === 'block'"
        >
          <projectRow
            :data="item"
            v-for="(item, index) in filterList"
            @listionEvent="listionEvent"
            :key="index"
          ></projectRow>
        </div>
        <!-- 竖排 -->
        <div @contextmenu.prevent="show($event)" v-else>
          <projectCell
            :data="item"
            v-for="(item, index) in filterList"
            :key="index"
            @listionEvent="listionEvent"
          >
          </projectCell>
        </div>
      </div>

      <div
        class="list-row home-list xpp-scrollbar"
        v-if="sort == 1 || sort == -1"
      >
        <!-- 横排 -->
        <div v-for="(item, index) in filterList" :key="index">
          <div class="row-list-span-title">{{ item.updateDate }}</div>
          <div
            class="home-project-list"
            @contextmenu.prevent="show($event)"
            v-if="rowOrCell === 'block'"
          >
            <projectRow
              :data="item"
              v-for="(item, index) in item.option"
              :key="index"
              @listionEvent="listionEvent"
            ></projectRow>
          </div>
          <!-- 竖排 -->
          <div @contextmenu.prevent="show($event)" v-else>
            <projectCell
              :data="item"
              v-for="(item, index) in item.option"
              :key="index"
              @listionEvent="listionEvent"
            ></projectCell>
          </div>
        </div>
      </div>

      <div
        class="list-row home-list xpp-scrollbar"
        v-if="sort == 3 || sort == -3"
      >
        <!-- 横排 -->
        <div v-for="(item, index) in filterList" :key="index">
          <div class="row-list-span-title">
            <div
              class="xpp-user-photo"
              :style="item.headImg | styleUserPhoto"
            ></div>
            <span>{{ item.nickName }}</span>
          </div>
          <div
            class="home-project-list"
            @contextmenu.prevent="show($event)"
            v-if="rowOrCell === 'block'"
          >
            <projectRow
              :data="item"
              v-for="(item, index) in item.option"
              :key="index"
              @listionEvent="listionEvent"
            ></projectRow>
          </div>
          <!-- 竖排 -->
          <div @contextmenu.prevent="show($event)" v-else>
            <projectCell
              :data="item"
              v-for="(item, index) in item.option"
              :key="index"
              @listionEvent="listionEvent"
            ></projectCell>
          </div>
        </div>
      </div>

      <div
        class="list-row home-list xpp-scrollbar"
        v-if="sort == 5 || sort == -5"
      >
        <!-- 横排 -->
        <div v-for="(item, index) in filterList" :key="index">
          <div class="row-list-span-title">
            <i
              class="iconfont icon-folder"
              :style="{ color: item.colour, fontSize: '22px' }"
            ></i>
          </div>
          <div
            class="home-project-list"
            @contextmenu.prevent="show($event)"
            v-if="rowOrCell === 'block'"
          >
            <projectRow
              :data="item"
              v-for="(item, index) in item.option"
              :key="index"
              @listionEvent="listionEvent"
            ></projectRow>
          </div>
          <!-- 竖排 -->
          <div @contextmenu.prevent="show($event)" v-else>
            <projectCell
              :data="item"
              v-for="(item, index) in item.option"
              :key="index"
              @listionEvent="listionEvent"
            ></projectCell>
          </div>
        </div>
      </div>
    </div>
    <div class="right_list xpp-scrollbar">
      <div class="list-one">
        <p class="title_p">
          最近paper
          <router-link to="/recent" rel="noopener">
            <span>显示全部</span>
          </router-link>
        </p>
        <dl
          class="info-top-canvas-container"
          v-for="item in xppLatelyPaper.slice(0, 5)"
          :key="item.id"
          @click="routerNav(item.id)"
        >
          <dt :style="item.canvasthumb | paperThumb"></dt>
          <dd>
            <p>{{ item.name }}</p>
            <p>修改于{{ item.updateDate | time }}</p>
          </dd>
        </dl>
      </div>
      <div class="list-two">
        <p>Hiten</p>
        <div class="heads" v-for="item in companyOnlineList" :key="item.userId">
          <div class="act-img">
            <div
              class="xpp-user-photo"
              :style="item.headImg | styleUserPhoto"
            ></div>
            <div class="img-status">
              <span></span>
            </div>
          </div>
          <div class="act-name">
            <div class="nickname">{{ item.nickName }}</div>
            <div class="email">{{ item.email }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import open from "@components/HomerightList";
import { mapGetters, mapActions, mapMutations } from "vuex";
import projectRow from "@/components/view/projectRow";
import projectCell from "@/components/view/projectCell";
import qs from "qs";
export default {
  name: "HomeProject",
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  watch: {
    list(val) {
      if (this.filterList.length < 1) {
        this.filterList = [...val];
        this.sort = localStorage.getItem("filter") * 1 || 1;
        this.sortValue = Math.abs(this.sort) || 1;
        this.filter(this.sort);
      } else {
        this.filterList = [...val];
        this.filter(this.sort);
      }
    }
  },
  computed: {
    ...mapGetters([
      "xppCompanyId",
      "rowOrCell",
      "xppProjectMemberInfo",
      "xppLatelyPaper",
      "companyOnlineList"
    ])
  },
  data() {
    return {
      menu: [],
      x: 0,
      y: 0,
      classType: "1", //选择的类型
      sort: 2, //排序的方式
      tmeplateObj: {},
      showID: "",
      pro: {},
      rightCss: {
        top: 30 + "px",
        left: 30 + "px",
        position: "fixed",
        "z-index": 999
      },
      isright: {
        type: false
      },
      filterList: [],
      activeName: "first",
      // 排序下拉
      sortOptions: [
        {
          calss: 1,
          text: "最后修改日期"
        },
        {
          calss: 2,
          text: "名称"
        },
        {
          calss: 3,
          text: "所有者"
        },
        {
          calss: 4,
          text: "创建日期"
        },
        {
          calss: 5,
          text: "标签"
        }
      ],
      // 下拉列表选中值
      sortValue: 1
    };
  },
  components: {
    open,
    projectRow,
    projectCell
  },
  mounted() {
    this.$UpdateCompanyOnlineList();
  },
  methods: {
    filter(v) {
      //为了不改变原数据 新加一个 filterList data数据在上放
      let sort = Math.abs(v);
      this.filterList = [
        ...this.$jok.sortFilter(this.list, {
          class: this.classType,
          sort: sort
        })
      ];
      this.sort = sort;
      localStorage.setItem("filter", this.sort);
    },
    listionEvent(e) {
      switch (e.event) {
        case "soucang":
        case "store":
          this.stars(e.value);
          break;
        case "mouseenter":
          this.tmeplateObj = e.value;
          break;
        case "set":
          this.tmeplateObj = e.value;
          this.show(e.e, true);
          break;
      }
    },
    show(ev, ov = false) {
      if (!ov && ev.target && ev.target.className == "home-project-list") {
        return;
      }
      if (!this.tmeplateObj.id) {
        return;
      }
      this.isright.type = false;
      this.menu = [];
      let userRoleId = this.tmeplateObj.contextmenu;
      this.showID = userRoleId;
      if (this.tmeplateObj.projectId) {
        switch (userRoleId) {
          case 4:
            this.menu = [4, 0, 5, 2, 10, 11, 12, 6];
            break;
          case 5:
          case 6:
          case 7:
            this.menu = [0, 5, 2, 10, 11];
            break;
        }
      } else {
        switch (userRoleId) {
          case 4:
            this.menu = [4, 5, 0, 1, 2, 6];
            break;
          case 6:
            this.menu = [4, 5, 0, 1, 2, 3];
            break;
          case 5:
          case 7:
            this.menu = [0, 1, 2, 3];
            break;
          case false:
            this.menu = [];
            return;
        }
      }
      if (window.openmenu) {
        window.openmenu.type = false;
      }
      this.$nextTick(() => {
        this.$set(this.isright, "type", true);
      });
      window.openmenu = this.isright;
      this.y = ev.y + "px";
      this.x = ev.x + "px";
      // }
    },
    handleClick(e) {
      this.filterList = [
        ...this.$jok.sortFilter([...this.list], {
          class: e.name,
          sort: this.sort
        })
      ];
    },

    sliceLength(val) {
      if (val instanceof Array) {
        return val.slice(0, 4);
      } else {
        return [];
      }
    },
    //右键的回调函数
    then() {
      //data 回调回来的数据
    },
    stars(obj) {
      let data = {
        companyId: this.xppCompanyId,
        sourceId: obj.id,
        sourceType: 1,
        collectionFlag: obj.collectionFlag == 1 ? 0 : 1
      };
      this.$axios.post("/dis/cl/collection", qs.stringify(data)).then(() => {
        if (obj.collectionFlag == 1) {
          obj.collectionFlag = 0;
        } else {
          obj.collectionFlag = 1;
        }
        if (obj.collectionFlag == 1) {
          this.$message("收藏成功");
        } else {
          this.$message("取消成功");
        }
      });
    },
    /**
     * @description: 排序形式
     * @param {string} para list or blockoqu
     */
    tabSort(para) {
      this.$tagRowOrCell(para);
    },
    // 路由跳转
    routerNav(id) {
      return window.open(`/paper/${id}`, "_blank", "noopener");
    },
    ...mapMutations(["$tagRowOrCell"]),
    ...mapActions(["$SetXppProjectMemberInfo", "$UpdateCompanyOnlineList"])
  }
};
</script>
<style lang="scss" scoped>
.icon-shoucang1,
.icon-favorites-icon {
  font-size: 12px;
}
.right-on {
  opacity: 0;
}
.right {
  border: 1px solid #ebebeb;
  padding: 20px 25px;
  border-radius: 5px;
  position: absolute;
  display: flex;
  align-items: center;
  top: 10px;
  right: 5px;
  width: 296px;
  box-sizing: border-box;
  p {
    height: 40px;
    line-height: 40px;
    width: 100px;
    text-align: left;
    color: #333;
    font-size: 20px;
    padding-right: 36px;
  }
  div {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: #666 url("/img/ios.png") no-repeat center/ 20px;
    border-radius: 100%;
    & + div {
      margin-left: 27px;
      background-image: url("/img/anzhuo.png");
    }
  }
}
.box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 156px);
  overflow: hidden;
  padding-bottom: 100px;
}
.right_list {
  flex: none;
  width: 300px;
  margin-left: 10px;
  margin-top: 40px;
  padding-bottom: 100px;
  height: calc(100% - 52px);
  z-index: 51;
  overflow: scroll;
  .info-top-canvas-container {
    height: auto;
    display: flex;
    line-height: 1;
    border-radius: 4px;
    border: 1px solid #ddd;
    cursor: pointer;

    dt {
      margin: 5px;
      height: 32px;
      width: 45px;
      border-radius: 3px;
      border: 1px solid rgba(151, 151, 151, 0.5);
      overflow: hidden;
      background-size: cover;
      background-position: center;
      float: left;
    }
    dd {
      float: left;
      margin-left: 0;
      & p:nth-child(1) {
        font-size: 12px;
        margin-top: 0.7em;
        padding-right: 20px;
        line-height: 12px;
      }
      & p:nth-child(2) {
        height: auto;
        line-height: 1.2;
        font-size: 10px;
        color: #8e959a;
        margin-top: 3px;
      }
    }
  }
}
.heads {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .nickname {
    line-height: 16px;
    font-size: 13px;
    font-weight: 600;
    color: #373839;
  }
  .email {
    font-size: 11px;
    color: #c9c9c9;
    line-height: 13px;
  }
}

.stars {
  width: 40px;
  height: 40px;
}

.stars img {
  width: 100%;
  height: 100%;
}
.favorites-menu {
  position: sticky;
  width: 100%;
  height: 30px;
  top: 0;
  left: 0;
  z-index: 99;
  background: #fff;
}
.home-favorites-page {
  padding: 26px 32px;
}
.home-favorites-tit {
  margin-bottom: 16px;
}
.stars {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.stars img {
  width: 100%;
  height: 100%;
}
.favorites-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .favorites-sort {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 220px;
    .f-select {
      ::v-deep .el-input__inner {
        border: none;
        width: 150px;
        text-align: right;
      }
    }
    .f-icon {
      cursor: pointer;
      i {
        font-size: 20px;
      }
      ::v-deep .f-none-color {
        color: #ddd;
      }
    }
  }
}
.home-favorites-page {
  .home-project-list {
    .home-item-page {
      flex: none;
      margin-bottom: 32px;
      margin-right: 16px;
      min-width: 200px;
      max-width: 282px;
      width: calc(20% - 16px);
    }
  }
}
.home-item-page {
  .canvas-item-in-container {
    background-color: #fbfbfc;
    border: 1px solid #e2e2e2;
    box-shadow: 0 1px 5px rgba(50, 50, 50, 0.1);
    border-radius: 6px;
    margin-top: 3px;
    .canvas-item-thumbnail {
      position: relative;
      padding: 16px;
      border-bottom: 1px solid #e2e2e2;
      box-sizing: border-box;
      padding-bottom: 0;
      .item-head {
        position: absolute;
        right: 0;
        span {
          width: 24px;
          height: 24px;
          z-index: 30;
          padding: 0;
          background-color: #fff;
          border-radius: 5px;
          border: 1px solid #e6e6e6;
          display: inline-block;
          text-align: center;
          line-height: 24px;
          margin-right: 5px;
          i {
            font-size: 18px;
          }
        }
      }
      .item-info {
        background-color: #fff;
        background-position: top center;
        background-size: cover;
        transition: border-color 0.2s;
        pointer-events: none;
        overflow: hidden;
        border-radius: 6px 6px 0 0;
        height: 82px;
      }
    }
    .canvas-item-info-container {
      padding: 9px 13px;
      background-color: #fff;
      border-radius: 6px;
      .canvas-title {
        font-size: 15px;
        font-weight: 500;
        color: #373839;
        line-height: 24px;
        display: flex;
        align-items: center;
        height: 24px;
        overflow: hidden;
        .hover-img {
          cursor: pointer;
        }
        img {
          width: 40px;
          height: 40px;
        }
        &:hover {
          .hover-img {
            display: inline-block;
          }
        }
      }
      .canvas-updata-time {
        font-size: 12px;
        font-weight: 500;
        color: #8e959a;
        max-width: 180px;
        display: inline-block;
        vertical-align: middle;
      }
      .canvas-name {
        display: flex;
        align-items: center;
        padding-top: 10px;
        .head-img {
          width: 24px;
          height: 24px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 8px;
          border: 1px solid #ccc;
        }
        .head-name {
          font-size: 13px;
          font-weight: 400;
          color: #8e959a;
        }
      }
    }
  }
}
.paperTitle {
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  width: 100%;
  background-color: transparent;
  top: -3px;
}
.paperTitle p {
  display: inline-block;
  line-height: 18px;
  font-size: 13px;
  margin-left: 6px;
  color: #8e959a;
  max-width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fol {
  width: 16px;
  height: 16px;
}
.list-row {
  margin-bottom: 20px;
  width: 100%;
  .home-project-list {
    padding-top: 20px;
  }
  .list-row-title {
    position: sticky;
    min-height: 1px;
    font-weight: 700;
    margin-left: -10px;
    display: block;
    background-color: #fff;
    box-shadow: 3px 0 0 0 #fff;
    z-index: 100;
    padding-bottom: 10px;
    padding-top: 14px;
    top: 30px;
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    color: #777;
  }
}
.home-list .home-project-cell:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.home-list {
  margin-top: 20px;
  margin-right: 20px;
  padding-left: 10px;
  position: relative;
  height: calc(100% - 40px);
  overflow: auto;
}
.list-one {
  border: 1px solid #ebebeb;
  padding: 18px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #ebebeb;
  .title_p {
    font-size: 13px;
    font-weight: 600;
    span {
      float: right;
      color: #f7bc03;
      font-weight: normal;
      font-size: 10px;
    }
  }
}
.list-two {
  border: 1px solid #ebebeb;
  padding: 18px 20px;
  border-radius: 5px;
  border: 1px solid #ebebeb;
}

.heads {
  display: flex;
  align-items: center;
  margin: 10px 0;
  .act-img {
    width: 32px;
    height: 32px;
    position: relative;
    .xpp-user-photo {
      width: 32px;
      height: 32px;
      float: left;
      margin-right: 5px;
      border: 1px solid #e2e2e2;
    }
    .img-status {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      z-index: 3;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
      top: 0;
      span {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #90ff01;
      }
      .frozen {
        background: red;
      }
    }
  }
  .act-name {
    margin-left: 10px;
  }
}
</style>
