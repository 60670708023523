<template>
  <div class="home-favorites-page">
    <div class="favorites-menu">
      <h2 class="home-favorites-tit">最近paper</h2>
      <!-- 排序 -->
      <div class="favorites-sort">
        <div class="f-icon" @click="$tagRowOrCell('list')">
          <i
            class="el-icon-s-fold"
            :class="rowOrCell === 'list' ? '' : 'f-none-color'"
          ></i>
        </div>
        <div class="f-icon" @click="$tagRowOrCell('block')">
          <i
            class="el-icon-menu"
            :class="rowOrCell === 'list' ? 'f-none-color' : ''"
          ></i>
        </div>
      </div>
    </div>
    <div class="home-project-list">
      <open
        :value="tmeplateObj"
        :menulist="menu"
        v-if="isright.type"
        :x="x"
        :y="y"
        class="index"
        @listionEvent="listionEvent"
      ></open>
      <div class="home-list xpp-scrollbar">
        <!-- 横排 -->
        <div
          class="home-project-list"
          @contextmenu.prevent="show($event)"
          v-if="rowOrCell === 'block'"
        >
          <div v-for="(item, index) in filterList" :key="index">
            <div class="recent-paper-title text-overflow">
              <i class="iconfont icon-folder"></i
              ><span>{{ item.projectName }}</span>
            </div>
            <paperRow :data="item" @listionEvent="listionEvent"></paperRow>
          </div>
        </div>
        <!-- 竖排 -->
        <div @contextmenu.prevent="show($event)" v-else>
          <paperCell
            :data="item"
            v-for="(item, index) in filterList"
            @listionEvent="listionEvent"
            :key="index"
          ></paperCell>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import open from "@components/HomerightList";
import { mapActions, mapGetters, mapMutations } from "vuex";
import paperRow from "@/components/view/paperRow";
import paperCell from "@/components/view/paperCell";
import qs from "qs";

export default {
  name: "recent",
  components: {
    open,
    paperRow,
    paperCell
  },
  computed: {
    ...mapGetters([
      "xppCompanyId",
      "xppUserInfo",
      "xppLatelyPaper",
      "rowOrCell",
      "globalUploadStatus"
    ])
  },
  watch: {
    xppLatelyPaper(val) {
      this.filterList = { ...val };
    },
    globalUploadStatus() {
      this.$UpdataUserProjectList();
    }
  },
  data() {
    return {
      menu: [],
      x: 0,
      y: 0,
      classType: 1,
      sortMenu: "list",
      sort: 2,
      tmeplateObj: {},
      filterList: [],
      pro: {},
      rightCss: {
        top: 30 + "px",
        left: 30 + "px",
        position: "fixed",
        "z-index": 999
      },
      //控制右键显示隐藏
      isright: {
        type: false
      }
    };
  },
  mounted() {
    this.filterList = [...this.xppLatelyPaper];
  },
  methods: {
    listionEvent(e) {
      switch (e.event) {
        case "soucang":
        case "store":
          this.stars(e.value);
          break;
        case "mouseenter":
          this.tmeplateObj = e.value;
          break;
        case "set":
          this.tmeplateObj = e.value;
          this.show(e.e, true);
          break;
      }
    },
    show(ev) {
      this.isright.type = false;
      this.menu = [];
      let userRoleId = 4;
      if (ev.target && ev.target.className == "home-project-list") {
        return;
      }
      if (this.tmeplateObj.projectId) {
        switch (userRoleId) {
          case 4:
            this.menu = [4, 0, 5, 2, 10, 11, 12, 6];
            break;
          case 5:
          case 6:
          case 7:
            this.menu = [0, 5, 2, 10, 11];
            break;
          case false:
            this.menu = [];
            return;
        }
      } else {
        switch (userRoleId) {
          case 4:
            this.menu = [4, 5, 0, 1, 2, 6];
            break;
          case 6:
            this.menu = [4, 5, 0, 1, 2, 3];
            break;
          case 5:
          case 7:
            this.menu = [0, 1, 2, 3];
            break;
          case false:
            this.menu = [];
            return;
        }
      }

      if (window.openmenu) {
        window.openmenu.type = false;
      }
      this.$nextTick(() => {
        this.$set(this.isright, "type", true);
        window.openmenu = this.isright;
      });
      this.y = ev.y + "px";
      this.x = ev.x + "px";
    },
    open_paper(id) {
      window.open("/paper/" + id, "_blank", "noopener");
      // this.$router.push({ name: "Paper", params: { id: id } });
    },
    stars(obj) {
      if (obj.collectionFlag == 1) {
        obj.collectionFlag = 0;
      } else {
        obj.collectionFlag = 1;
      }
      let data = {
        companyId: this.xppUserInfo.companyId,
        sourceId: obj.id,
        sourceType: 2,
        collectionFlag: obj.collectionFlag
      };
      this.$axios.post("/dis/cl/collection", qs.stringify(data)).then(() => {
        if (obj.collectionFlag == 1) {
          this.$message("收藏成功");
        } else {
          this.$message("取消成功");
        }
      });
    },
    ...mapMutations(["$tagRowOrCell"]),
    ...mapActions(["$UpdataUserProjectList"])
  }
};
</script>

<style lang="scss" scoped>
.home-favorites-page {
  position: relative;
  padding: 26px 32px 100px;
}
.home-list {
  height: calc(100vh - 156px);
  overflow-y: scroll;
  width: 100%;
}
.home-favorites-tit {
  height: 45px;
  line-height: 45px;
  font-size: 18px;
  font-weight: 600;
  color: #373839;
}
.favorites-menu {
  position: relative;
  margin-bottom: 10px;
}
.favorites-sort {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50px;
  top: 0px;
  right: 13px;
  .f-icon {
    cursor: pointer;
    i {
      font-size: 20px;
    }
    ::v-deep .f-none-color {
      color: #ddd;
    }
  }
}
.recent-paper-title {
  max-width: 95%;
  height: 20px;
  line-height: 20px;
  .iconfont {
    color: #f7bc03;
    font-size: 18px;
  }
  span {
    line-height: 18px;
    font-size: 13px;
    margin-left: 6px;
    color: #8e959a;
  }
}
</style>
