<template>
  <div
    class="yy"
    @click.prevent="contextmenu"
    v-if="isshow"
    @contextmenu.stop="contextmenu1"
  >
    <div class="contextmenu" :style="{ top: top, left: left }">
      <div
        v-for="(item, index) in list"
        :key="index"
        @click="menu(item.index), (index = item.index)"
        class="contextmenu-item"
      >
        <i :class="'icon ' + item.icon"></i>
        {{ item.title }}
        <template v-if="item.index == 1">
          <div class="color">
            <p
              v-for="item in tag"
              :key="item.index"
              :style="{ background: item.color }"
              @click="iscolor(item.color)"
            ></p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { aliyunPut } from "@plugins/aliyunOss";

export default {
  name: "contextmenu",
  props: ["value", "menulist", "x", "y"],
  data() {
    return {
      dialogVisible: false,
      resourceType: "",
      index: 99, //默认值
      list: [],
      top: 0,
      left: 0,
      isshow: true,
      tag: [
        {
          color: "#f7bd02",
          value: 0
        },
        {
          color: "#fb616c",
          value: 1
        },
        {
          color: "#fe9055",
          value: 2
        },
        {
          color: "#4cdb6c",
          value: 3
        },
        {
          color: "#4ac0f7",
          value: 4
        },
        {
          color: "#9777f8",
          value: 5
        }
      ],
      data: [
        { title: "添加到收藏夹", icon: "iconfont icon-shoucang2", index: 0 },
        { title: "添加标签", icon: "iconfont icon-paint-line", index: 1 },
        { title: "成员管理", icon: "iconfont icon-tianjialianxiren", index: 2 },
        { title: "退出本项目", icon: "iconfont icon-delete", index: 3 },
        { title: "设置", icon: "iconfont icon-setting", index: 4 },
        { title: "上传缩略图", icon: "iconfont icon-image", index: 5 },
        { title: "回收站", icon: "iconfont icon-delete", index: 6 },
        { title: "还原", icon: "el-icon-receiving", index: 7 },
        { title: "删除", icon: "iconfont icon-delete", index: 8 },
        { title: "分享", icon: "iconfont icon-share", index: 9 },
        { title: "分享", icon: "iconfont icon-share", index: 10 },
        { title: "副本", icon: "iconfont icon-duplicate_icon", index: 11 },
        {
          title: "移动到",
          icon: "iconfont icon-page-size-change-icon",
          index: 12
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["xppCompanyId", "dialogInvitationToJoin"])
  },
  methods: {
    ...mapMutations([
      "$UpdataVuexState",
      "$DialogInvitationToJoinShow",
      "$outProject",
      "$setContextMenu",
      "$setRefresh",
      "$setGlobalUploadStatys"
    ]),
    ...mapActions([
      "$SetXppPaperShareDialogInfo",
      "$opensetUp",
      "$GetResourceMemberList"
    ]),
    handleClose() {},
    contextmenu() {
      this.isshow = false;
      return false;
    },
    contextmenu1() {
      this.isshow = false;
      return;
    },
    iscolor(index) {
      this.value.colour = index;
      let data = new FormData();
      data.append("companyId", this.value.companyId);
      data.append("projectId", this.value.id);
      data.append("colour", index);
      this.$axios.post("/dis/project/addcolour", data);
    },
    async menu(index) {
      //    0 添加到收藏夹
      //    1 添加标签
      //    2 成员管理
      //    3 退出本项目
      //    4 设置
      //    5 上传缩略图
      //    6 回收站
      //    7 还原
      //    8 删除项目o
      //    9 删除
      //    10 分享
      //    11 副本
      //    12 取消收藏
      console.log(this.value, index);
      switch (Math.abs(index)) {
        case 0:
          this.$emit("listionEvent", { event: "soucang", value: this.value });
          break;
        case 1:
          break;
        case 2:
          if (this.value.projectId) {
            this.resourceType = 2;
            this.$setContextMenu({ type: 2 });
          } else {
            this.resourceType = 1;
            this.$setContextMenu({ type: 1 });
          }
          this.$UpdataVuexState({
            key: "xppProjectMemberInfo",
            data: {
              status: true,
              projectId: this.value.id,
              title: this.value.name
            }
          });
          this.$GetResourceMemberList({
            resourceId: this.value.sourceId || this.value.id,
            resourceType: this.resourceType + ""
          });
          break;
        case 3:
          this.$outProject({ show: true, type: 1, project: this.value });
          break;

        case 4:
          if (this.value.projectId) {
            this.$opensetUp({
              status: true,
              id: this.value.id,
              name: this.value.name,
              type: 1
            });
          } else {
            this.$opensetUp({
              status: true,
              id: this.value.id,
              name: this.value.name,
              type: 0
            });
          }
          break;

        case 5:
          let input = document.createElement("input");
          input.type = "file";
          input.onchange = async ({ target }) => {
            var file = await aliyunPut(target.files[0]);
            if (!file.res) {
              return this.$message.error("上传未完成");
            }
            this.value.canvasthumb = `https://5gcoolwork.oss-accelerate.aliyuncs.com/${file.name}`;
            var formdata = new FormData();
            formdata.append("paperId", this.value.id);
            formdata.append("thump", this.value.canvasthumb);
            formdata.append("thumbup", true);
            formdata.append("rootParentFlag", 1);
            this.$axios.post("/mo/paper/updatethumb", formdata).then(() => {
              this.$setRefresh();
            });
          };
          input.click();
          break;
        case 6:
          this.$outProject({
            show: true,
            type: 2,
            project: this.value
          });
          break;
        case 7:
          (() => {
            if (this.value.projectId) {
              this.resourceType = 2;
              console.log(this.value.projectId, "paper");
            } else {
              this.resourceType = 1;
              console.log(this.value.projectId, "xiangmu");
            }
            this.$axios
              .post("/dis/recycle/add", {
                resourceType: this.resourceType,
                id: this.value.id,
                delFlag: 0
              })
              .then(res => {
                this.$message("还原成功");
                console.log(res);
                this.$emit("listionEvent", {
                  event: "update"
                });
                this.$setGlobalUploadStatys();
              });
          })();
          break;
        case 8:
          this.$outProject({
            show: true,
            type: 3,
            project: this.value
          });
          break;
        case 9:
          // this.$SetXppPaperShareDialogInfo({
          //   id: this.value.id,
          //   name: this.value.name
          // });
          break;
        case 10:
          this.$SetXppPaperShareDialogInfo({
            id: this.value.id,
            name: this.value.name
          });
          break;
      }
    }
  },
  mounted() {
    document.getElementsByClassName("yy")[0].oncontextmenu = function() {
      return false;
    };
    let data = [];
    if (this.menulist.indexOf(0) != -1) {
      if (this.value.collectionFlag == 1) {
        this.data[0] = {
          title: "取消收藏",
          icon: "iconfont icon-shoucang1",
          index: 0
        };
      } else {
        this.data[0] = {
          title: "添加到收藏夹",
          icon: "iconfont icon-shoucang2",
          index: 0
        };
      }
    }
    this.menulist.forEach(el => {
      data.push(this.data[el]);
    });
    let clientWidth = document.body.clientWidth;
    let clientHeight = document.body.clientHeight;
    let y = parseFloat(this.y);
    let x = parseFloat(this.x);
    if (y + 42 * this.menulist.length > clientHeight) {
      this.top = y - 42 * this.menulist.length + "px";
    } else {
      this.top = y + "px";
    }
    if (x + 240 > clientWidth) {
      this.left = x - 240 + "px";
    } else {
      this.left = x + "px";
    }
    this.list = data;
  }
};
</script>

<style lang="scss" scoped>
.contextmenu {
  position: fixed;
  width: 240px;
  padding: 4px 0;
  background-color: #fff;
  font-size: 14px;
  border: 1px solid #dbdee6;
  z-index: 99;
}
.icon {
  margin: 0 10px;
}
.contextmenu-item {
  cursor: pointer;
  position: relative;
  padding: 0 16px;
  height: 42px;
  color: #8e959a;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.contextmenu-item:hover {
  background: #ccc;
}
.contextmenu-item div {
  display: none;
  position: absolute;
  left: 100%;
  width: 120px;
  height: 80px;
  border: solid 1px #ccc;
  background: #fff;
}
.contextmenu-item:hover div {
  display: block;
}
.color {
  p {
    width: 20px;
    height: 20px;
    margin: 10px;
    border-radius: 50%;
    display: inline-block;
  }
}
.yy {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 990;
}
.contextmenu {
  animation: keyup 300ms;
}
@keyframes keyup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
