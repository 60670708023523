<template>
  <div class="sidenav-container">
    <router-link to="/project" tag="div" class="sidenav-logo" rel="noopener">
      <img src="/img/ic_colored.png" alt="logo" />
    </router-link>
    <div class="article-menu-list">
      <ul>
        <router-link to="/project" rel="noopener">
          <li :class="active == '/project' ? 'nav-active' : ''">
            <div>
              <i class="article-menu-icon menu-icon-home"></i>
              <span>Hiten</span>
            </div>
          </li>
        </router-link>
        <router-link to="/received" rel="noopener">
          <li :class="active == '/received' ? 'nav-active' : ''">
            <div>
              <i class="article-menu-icon menu-icon-receive"></i>
              <span>收到的Paper</span>
            </div>
          </li>
        </router-link>
        <router-link to="/recent" rel="noopener">
          <li :class="active == '/recent' ? 'nav-active' : ''">
            <div>
              <i class="article-menu-icon menu-icon-lately"></i>
              <span>最近的Paper</span>
            </div>
          </li>
        </router-link>
      </ul>
      <ul>
        <router-link to="/favorites" rel="noopener">
          <li :class="active == '/favorites' ? 'nav-active' : ''">
            <div>
              <i class="article-menu-icon menu-icon-collect"></i>
              <span>收藏</span>
            </div>
          </li>
        </router-link>
        <router-link to="/active" rel="noopener">
          <li :class="active == '/active' ? 'nav-active' : ''">
            <div>
              <i class="article-menu-icon menu-icon-activity"></i>
              <span>活动</span>
            </div>
          </li>
        </router-link>
      </ul>
      <ul>
        <router-link to="/recyclebin" rel="noopener">
          <li :class="active == '/recyclebin' ? 'nav-active' : ''">
            <div>
              <i class="article-menu-icon menu-icon-recycle"></i>
              <span>回收站</span>
            </div>
          </li>
        </router-link>
      </ul>
    </div>
    <div class="sidenav-bottom">
      <div class="sidenav-bottom-menus">
        <p class="p">
          <i class="article-menu-icon menu-icon-manage"></i><span>Hiten</span>
        </p>
        <router-link tag="p" class="pp" to="/member" rel="noopener">
          <i class="article-menu-icon menu-icon-member"></i
          ><span>{{ xppTeamMemberList.length }}成员</span>
        </router-link>
        <div class="courlor" v-if="userRoleInTheTeam">
          <router-link tag="div" to="/member" rel="noopener"
            >管理控制台</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Sidenav",
  computed: {
    ...mapGetters([
      "xppCompanyId",
      "xppUserInfo",
      "userRoleInTheTeam",
      "xppTeamMemberList"
    ]),
    active() {
      return this.$router.currentRoute.path;
    }
  },
  mounted() {
    var lis = document.querySelectorAll("li");
    lis.forEach(item => {
      item.onclick = () => {
        lis.forEach(item => {
          item.className = "";
        });
        item.className = "nav-active";
      };
    });
  }
};
</script>

<style lang="scss" scoped>
.sidenav-container {
  width: 208px;
  background-color: #f4f4f4;
}

.sidenav-logo {
  cursor: pointer;
  margin: 24px auto 104px 30px;
  padding: 0;
  width: 40px;
  height: 40px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
.nav-active {
  color: #f5a623 !important;
  opacity: 1;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 5px;
    background-color: #f5a623;
    border-radius: 0 4px 4px 0;
    top: -2px;
    bottom: -2px;
    height: auto;
    transition: width 0.2s;
  }
  .article-menu-icon {
    background-image: url(/img/article-menu-icon-ac.png);
  }
}

.article-menu-list {
  ul {
    margin-bottom: 28px;
  }

  li {
    position: relative;
    cursor: pointer;
    color: #8e959a;
    margin: 10px 0;

    div {
      width: calc(100% - 48px);
      max-width: 80%;
      margin-left: 24px;
      height: 24px;
      display: flex;
      align-items: center;
    }

    .iconfont,
    span {
      color: inherit;
      line-height: 1;
      flex: none;
    }

    .iconfont {
      font-weight: bold;
    }

    span {
      font-size: 15px;
      font-weight: 600;
      margin-left: 10px;
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
.sidenav-bottom {
  background-color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  flex-direction: column;
  width: 208px;
  width: 186px;
  margin: 0 10px;
}

.p,
.pp {
  display: flex;
  align-items: center;
  i {
    margin-right: 0.5em;
  }
}

.p {
  padding: 15px 10px 10px;
  line-height: 16px;
  font-size: 13px;
}
.pp {
  padding: 0 10px 10px;
}
.courlor {
  width: 90%;
  margin: 0 auto;
  border-top: 1px solid #eee;
}
.courlor div {
  margin: 10px auto;
  cursor: pointer;
  background-color: #eee;
  text-align: center;
  font-size: 13px;
  padding: 5px 0;
  width: 100%;
  font-weight: 600;
  border-radius: 5px;
}
.article-menu-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(/img/article-menu-icon.png);
  background-repeat: no-repeat;
  &.menu-icon-home {
    background-position: center 0;
  }
  &.menu-icon-receive {
    background-position: center -29px;
  }
  &.menu-icon-lately {
    background-position: center -58px;
  }
  &.menu-icon-collect {
    background-position: center -89px;
  }
  &.menu-icon-activity {
    background-position: center -117px;
  }
  &.menu-icon-recycle {
    background-position: center -142px;
  }
  &.menu-icon-manage {
    background-position: center -168px;
  }
  &.menu-icon-member {
    background-position: center -194px;
  }
}
</style>
