<template>
  <div
    class="projectRow home-project-item"
    @click.stop="open_paper(data.id)"
    @contextmenu.prevent="send('mouseenter')"
  >
    <div class="tran" v-if="data.collectionFlag == 1"></div>
    <div class="top">
      <div class="top-content">
        <div class="top-content-item">
          <img v-if="data.canvasthumb" :src="data.canvasthumb" />
        </div>
      </div>
      <div class="share" v-if="data.contextmenu">
        <el-tooltip
          class="item"
          effect="dark"
          content="分享"
          placement="top"
          @click.stop="send('share', $event)"
        >
          <div class="set-more">
            <i class="el-icon-share"></i>
          </div>
        </el-tooltip>
      </div>
      <div class="set" v-if="data.contextmenu">
        <el-tooltip class="item" effect="dark" content="设置" placement="top">
          <div class="set-more" @click.stop="send('set', $event)">
            <i class="el-icon-more"></i>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="bottom">
      <div class="title">
        <span class="text-overflow">{{ data.name }}</span>
        <i
          class="el-icon-star-on"
          @click.stop="send('store')"
          :id="data.collectionFlag == 1 ? 'active' : ''"
        ></i>
      </div>
      <div class="time">修改时间{{ data.updateDate | time }}</div>
      <div class="creator" v-if="data.creator">
        <div
          class="xpp-user-photo"
          :style="data.creator.headImg | styleUserPhoto"
        ></div>
        <span>{{ data.creator.nickName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "paperRow",
  props: ["data"],
  methods: {
    splitMst(list) {
      return list.slice(0, 4);
    },
    send(value, e = {}) {
      this.$emit("listionEvent", {
        event: value,
        value: this.data,
        e: { x: e.x - e.layerX, y: e.y - e.layerY + 28 }
      });
    },
    open_paper() {
      window.open(
        `/paper/${this.data.sourceId || this.data.id}`,
        "_blank",
        "noopener"
      );
      // this.$router.push({
      //   name: "Paper",
      //   params: { id: this.data.sourceId || this.data.id }
      // });
    }
  }
};
</script>

<style lang="scss" scoped>
.openflag {
  height: 24px;
  width: auto;
  line-height: 24px;
  display: flex;
  border-radius: 4px;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  padding: 0 5px;
  align-items: center;
  transition: background 0.2s;
  background-color: #fcfcfc;
  position: absolute;
  left: 13px;
  top: 13px;
  z-index: 99;
}
.projectRow {
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.15s;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  box-shadow: 0 1px 5px rgba(50, 50, 50, 0.1);
  position: relative;

  .bottom {
    position: relative;
    z-index: 2;
    border-top: 1px solid #dedede;
    padding: 11px 13px 15px;
    transition: all 0.3s;
    &:hover {
      background: #fcfcfc;
    }
    &:hover .el-icon-star-on {
      opacity: 1;
    }
    .time {
      font-size: 12px;
      font-weight: 500;
      color: #8e959a;
      max-width: 180px;
      display: inline-block;
      margin-top: 5px;
      vertical-align: middle;
    }
    #active {
      color: #f7bc03;
      opacity: 1;
    }
    .el-icon-star-on {
      color: #8e959a;
      font-size: 18px;
      transition: all 0.2s;
      opacity: 0;
      margin: 0 2px;
      &:hover {
        color: #373839;
      }
    }
    .creator {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .xpp-user-photo {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      span {
        font-size: 13px;
        font-weight: 400;
        color: #8e959a;
      }
    }
  }
  .top {
    width: 100%;
    height: 99px;
    padding: 0;
    padding: 16px;
    padding-bottom: 0;
    box-sizing: border-box;
    position: relative;
    background-color: #fbfbfb;
    overflow: hidden;
    .top-content {
      background-position: top center;
      background-size: cover;
      transition: border-color 0.2s;
      background-color: #ffffff;
      pointer-events: none;
      border-radius: 6px 6px 0 0;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.22);
      .top-content-item {
        display: block;
        min-height: 160px;
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .icon {
      font-size: 58px;
    }
  }
  .set {
    position: absolute;
    right: 15px;
    top: 10px;
    border: 1px solid #dbdee6;
    border-radius: 6px;
    background: #fcfcfc;
    padding: 3px 5px;
    z-index: 5;
  }
  .share {
    position: absolute;
    right: 50px;
    top: 10px;
    border: 1px solid #dbdee6;
    border-radius: 6px;
    background: #fcfcfc;
    z-index: 5;
    width: 24px;
    height: 24px;
    display: flex;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    justify-self: start;
  }
}

.home-project-item {
  flex: none;
  margin-bottom: 32px;
  margin-right: 16px;
  min-width: 200px;
  max-width: 282px;
  width: calc(20% - 16px);
  height: auto;
}

.title {
  display: flex;
  align-items: center;
  span {
    flex: auto;
    max-width: calc(100% - 20px);
  }
  i {
    flex: none;
  }
}
@media (max-width: 1720px) {
  .home-project-item {
    width: calc(25% - 16px);
  }
}

@media (max-width: 1500px) {
  .home-project-item {
    width: calc(33% - 16px);
  }
}

@media (max-width: 1280px) {
  .home-project-item {
    width: calc(50% - 16px);
  }
}
.tran {
  margin: 0 auto;
  background: #f7bc03;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: tran 0.5s;
  border-radius: 100%;
}
@keyframes tran {
  0% {
    width: 30px;
    height: 30px;
    opacity: 0.5;
  }
  75% {
    width: 150%;
    height: 150%;
  }
  100% {
    opacity: 0;
    width: 100%;
  }
}
</style>
