<template>
  <div
    class="projectCell home-project-cell"
    @contextmenu.prevent="send('mouseenter')"
    @click.stop="openProject"
  >
    <div class="tran" v-if="data.collectionFlag == 1"></div>
    <div class="home-project-cell-left">
      <svg class="folder-icon" width="28px" height="28px" viewBox="0 0 64 64">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g :fill="data.colour" class="project-folder-color">
            <g transform="translate(0.000000, -1.000000)">
              <g transform="translate(0.000000, 6.000000)">
                <path
                  d="M25.6,0 L6.4,0 C2.864,0 0.032,2.90875 0.032,6.5 L0,45.5 C0,49.0912484 2.864,52 6.4,52 L57.6,52 C61.136,52 64,49.0912484 64,45.5 L64,13 C64,9.40875 61.136,6.5 57.6,6.5 L32,6.5 L25.6,0 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <div class="title">
        <span>{{ data.name }}</span>
        <span v-if="!data.openFlag" class="openflag">
          <i class="el-icon-lock"></i>非公开</span
        >
        <i
          v-if="data.contextmenu"
          class="el-icon-star-on"
          @click.stop="send('store')"
          :id="data.collectionFlag == 1 ? 'active' : ''"
        ></i>
        <div class="time">修改于{{ data.updateDate | time }}</div>
      </div>
    </div>
    <div class="home-project-cell-right">
      <div class="avatar-list">
        <el-tooltip
          class="item"
          v-for="item in membersList"
          :key="item.userId"
          effect="dark"
          :content="item.nickName"
          placement="top"
        >
          <div
            class="avatar xpp-user-photo"
            :style="item.headImg | styleUserPhoto"
          ></div>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="成员管理"
          placement="top"
        >
          <div class="avatar">
            {{ data.membersList.length }}
          </div>
        </el-tooltip>
      </div>
      <el-tooltip
        class="item"
        effect="dark"
        content="设置"
        placement="top"
        :class="nodeVisibility"
      >
        <div class="set-more" @click.stop="send('set', $event)">
          <i class="el-icon-more"></i>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "projectCell",
  props: ["data"],
  computed: {
    membersList() {
      return this.data.membersList.slice(0, 4);
    },
    nodeVisibility() {
      return {
        "node-hide": !this.data.contextmenu
      };
    }
  },
  methods: {
    send(value, e = {}) {
      this.$emit("listionEvent", {
        event: value,
        value: this.data,
        e: { x: e.x - 240 + 28, y: e.y - e.layerY + 38 }
      });
    },
    openProject({ target }) {
      if (target.classList.contains("set-more")) return;
      this.$router.push({
        name: "HomeProjectPaper",
        params: {
          id: this.data.sourceId || this.data.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.home-project-cell {
  position: relative;
  cursor: pointer;
  display: flex;
  width: calc(100% - 2px);
  height: 80px;
  padding: 0;
  overflow: hidden;
  box-shadow: 0 0 0 1px #e2e2e2;
  margin: 0 auto 1px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  transition: background-color 0.2s, box-shadow 0.2s, transform 0.15s;
  &:hover {
    background: rgba(0, 0, 0, 0.03);
    .el-icon-star-on {
      opacity: 1;
    }
  }
}
.home-project-cell-left,
.home-project-cell-right {
  display: flex;
  align-items: center;
}
.home-project-cell-left {
  .folder-icon {
    margin-left: 25px;
    margin-right: 15px;
  }
  .time {
    font-size: 12px;
    font-weight: 500;
    color: #8e959a;
    max-width: 180px;
    margin-top: 5px;
  }
  #active {
    color: #f7bc03;
    opacity: 0.7;
  }
  .el-icon-star-on {
    color: #8e959a;
    font-size: 18px;
    transition: all 0.2s;
    opacity: 0;
    margin: 0 2px;
    &:hover {
      color: #373839;
    }
  }
}
.home-project-cell-right {
  .avatar-list {
    display: flex;
    align-items: center;
  }
  .avatar {
    width: 28px;
    height: 28px;
    margin-right: 8px;
    border: 1px solid #dbdee6;
    border-radius: 50%;
    background-color: #fcfcfc;
    transition: background 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #ccc;
    overflow: hidden;
    &:last-child {
      margin-right: 0;
      &:hover {
        background: #abb2b8;
        color: #fff;
      }
    }
  }
  .set-more {
    position: relative;
    border: 1px solid #dbdee6;
    border-radius: 6px;
    background: #fcfcfc;
    padding: 3px 5px;
    margin-left: 20px;
    margin-right: 15px;
  }
  .node-hide {
    visibility: hidden;
  }
  .el-icon-more {
    pointer-events: none;
  }
}
.openflag {
  display: inline-block;
  font-size: 11px;
  color: #484d57;
  letter-spacing: 0.2px;
  margin-left: 3px;
  margin-right: 5px;
  height: 22px;
  line-height: 22px;
  i {
    padding: 0 5px;
  }
}
.tran {
  margin: 0 auto;
  background: #f7bc03;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: tran 0.3s;
}
@keyframes tran {
  0% {
    width: 30px;
    height: 30px;
    opacity: 0.3;
  }
  75% {
    width: 100%;
    height: 100%;
  }
  100% {
    opacity: 0;
    width: 100%;
  }
}
</style>
