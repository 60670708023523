<template>
  <div class="home-index-page">
    <div class="home-index-content-box">
      <h2 class="display-flex">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            v-for="item in breadcrumb"
            :key="item.id"
            :to="item.to"
            >{{ item.tit }}
          </el-breadcrumb-item>
        </el-breadcrumb>

        <span class="breadcrumb-delimiter"></span>

        <el-button
          type="warning"
          size="small"
          icon="el-icon-plus"
          class="theme2-btn"
          @click="openCreatePaperDialog"
          >{{ labelInfo.createBtnText }}
        </el-button>
      </h2>
      <router-view
        class="home-index-con"
        :list="paperList"
        @cpaper="openCreatePaperDialog"
        @upload="upDataPaperList"
      />
    </div>

    <div class="home-index-information xpp-scrollbar" v-if="seePaperItemOff">
      <!-- 是否已经加入此项目 -->
      <div class="home-index-paper-info" v-if="!partakeProjectState">
        <h3 class="text-overflow">
          你正在查看<b>{{ projectName }}</b>
        </h3>
        <p>
          由 {{ projectPaperStateInfo.nickName }} 于
          {{ projectPaperStateInfo.createDate | modifiedTime }} 创建
        </p>
        <el-button
          type="success"
          size="mini"
          @click="changeProjectPartakeState(1)"
          >加入项目</el-button
        >
      </div>
      <!-- 项目加入/退出 -->
      <div class="xpp-index-information-item xpp-partake-content">
        <div class="display-flex">
          <span class="xpp-partake-state">{{
            partakeProjectInfo.openFlag ? "公开" : "非公开"
          }}</span>
          <el-select
            class="xpp-partake-select"
            v-model="partakeProjectState"
            size="mini"
            popper-class="xpp-partake-select-item"
            :class="{ 'xpp-partake-joined': partakeProjectState }"
            :disabled="projectFounderStatus"
            @change="changeProjectPartakeState"
          >
            <el-option
              v-for="item in partakeProjectStateSelect"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <p>
          {{
            partakeProjectInfo.openFlag
              ? `所有在${projectPaperStateInfo.companyName}中的成员都可以加入此项目`
              : "仅自己和被邀请的人可查看并编辑"
          }}
        </p>
      </div>
      <!-- 项目说明 -->
      <div class="xpp-index-information-item ">
        <div class="xpp-index-information-title display-flex">
          <span>说明</span>
        </div>
        <div class="xpp-index-information-content">
          <el-input
            placeholder="编辑描述"
            v-model="projectPaperStateInfo.description"
            size="mini"
            :disabled="true"
          >
          </el-input>
        </div>
      </div>
      <!-- 项目成员 -->
      <div class="xpp-index-information-item">
        <div class="xpp-index-information-title display-flex">
          <span>成员</span>
          <el-button
            v-if="xppProjectMemberInfo.observerAuthority"
            type="text"
            @click="openMemberDialog"
            >管理</el-button
          >
        </div>
        <div class="xpp-index-information-content">
          <div class="xpp-project-members-list display-flex flex-start">
            <el-tooltip content="添加团队成员" placement="top">
              <div
                class="xpp-project-members-add el-icon-plus"
                @click="openInvitationDialog(1)"
              ></div>
            </el-tooltip>
            <!-- className user-pic 标识在线状态 -->
            <div
              class="xpp-project-members-item"
              v-for="item in projectMemberlist"
              :key="item.userId"
            >
              <el-tooltip
                :content="item.nickName"
                placement="top"
                :enterable="false"
              >
                <div
                  class="xpp-user-photo"
                  :style="item.headImg | styleUserPhoto"
                ></div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <!-- 外部合作者 -->
      <div class="xpp-index-information-item">
        <div class="xpp-index-information-title display-flex">
          <span>外部合作者</span>
        </div>
        <div class="xpp-index-information-content">
          <div class="xpp-project-members-list display-flex flex-start">
            <el-tooltip content="添加合作者" placement="top">
              <div
                class="xpp-project-members-add el-icon-plus"
                @click="openInvitationDialog(2)"
              ></div>
            </el-tooltip>
            <!-- className user-pic 标识在线状态 -->
            <div
              class="xpp-project-members-item"
              v-for="item in projectPartnerlist"
              :key="item.userId"
            >
              <el-tooltip
                :content="item.nickName"
                placement="top"
                :enterable="false"
              >
                <div
                  class="xpp-user-photo"
                  :style="item.headImg | styleUserPhoto"
                ></div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <!-- 项目信息 -->
      <div class="xpp-index-information-item ">
        <div class="xpp-index-information-title display-flex">
          <span>信息</span>
        </div>
        <div class="xpp-index-information-content">
          最后修改于 {{ projectPaperStateInfo.updateDate | modifiedTime }}
          <br />
          由 {{ projectPaperStateInfo.nickName }} 于
          {{ projectPaperStateInfo.createDate | modifiedTime }} 创建
        </div>
      </div>
    </div>
    <!--  新建paper  -->
    <el-dialog
      :title="labelInfo.createBtnText"
      :visible.sync="dialogCreatePaperVisible"
      :close-on-click-modal="false"
      custom-class="xpp-creat-paper-dialog"
      @close="resetForm"
      width="839px"
    >
      <el-form
        label-position="top"
        label-width="80px"
        :rules="rule"
        :model="formPaperData"
        @submit.native.prevent
        ref="formPaper"
      >
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="formPaperData.name"
            @keyup.enter.native.prevent
            placeholder="名称"
            ref="titleEditInput"
          ></el-input>
        </el-form-item>

        <el-form-item label="权限" prop="openFlag">
          <el-select
            class="xpp-power-select"
            v-model="formPaperData.openFlag"
            placeholder="请选择"
            popper-class="xpp-creat-power-option"
          >
            <el-option
              v-for="item in creatPaperPower"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <p>{{ item.label }}</p>
              <p v-if="item.value === '1'">
                所有在<b>{{ item.organize }}</b
                >中的成员都可查看并编辑此项目
              </p>
              <p v-else>{{ item.annotation }}</p>
            </el-option>
          </el-select>
        </el-form-item>
        <template v-if="seePaperItemOff">
          <div class="paper-creat-config">
            <div class="paper-creat-config-tit">
              <span
                :class="{ 'state-active': formPaperData.creatType === 1 }"
                data-type="1"
                @click="changePaperCreatType"
                >空白项目</span
              >
              <span
                :class="{ 'state-active': formPaperData.creatType === 2 }"
                data-type="2"
                @click="changePaperCreatType"
                >从模板中创建项目</span
              >
            </div>
            <div class="paper-creat-config-con">
              <div
                class="paper-creat-config-option display-flex flex-start"
                v-if="formPaperData.creatType === 1"
              >
                <div class="paper-creat-standard">
                  <input
                    class="paper-cs-radio-input"
                    type="radio"
                    name="standard"
                    value="1"
                    v-model="formPaperData.standard"
                  />
                  <div class="paper-cs-bg">
                    <div class="paper-cs-type1"></div>
                  </div>
                  <p>幻灯片</p>
                </div>
                <div class="paper-creat-standard">
                  <input
                    class="paper-cs-radio-input"
                    type="radio"
                    name="standard"
                    value="2"
                    v-model="formPaperData.standard"
                  />
                  <div class="paper-cs-bg">
                    <div class="paper-cs-type2"></div>
                  </div>
                  <p>页面</p>
                </div>
              </div>
              <div
                class=" paper-creat-config-option cpaper-template-container display-flex"
                v-else
              >
                <div
                  class="cpaper-template-cbtn cpaper-template-left display-flex"
                >
                  <i class="el-icon-arrow-left"></i>
                </div>
                <div class="cpaper-template-select">
                  <div
                    class="cpaper-template-selectbox display-flex flex-start"
                  >
                    <div
                      class="cpaper-template-selectitem"
                      v-for="item in cpaperTemplateSelect"
                      :key="item.id"
                    >
                      <input
                        type="radio"
                        name="standard"
                        :value="item.id"
                        v-model="formPaperData.standard"
                      />
                      <div
                        :class="{
                          'templ-active': formPaperData.standard === item.id
                        }"
                        :data-template="item.id"
                      ></div>
                      <p>{{ item.name }}</p>
                    </div>
                  </div>
                </div>
                <div
                  class="cpaper-template-cbtn cpaper-template-right display-flex"
                >
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </template>

        <el-form-item class="text-right">
          <el-button
            type="warning"
            :loading="createNewPaperLoad"
            @click="createNewPaper"
            >创建
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { formParamsFormat } from "@plugins/toolWheel";
import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
  name: "HomeIndex",
  beforeRouteUpdate(target, source, next) {
    if (target.name === "HomeProject") {
      this.breadcrumb.length = 1;
      this.seePaperItemOff = false;
      this.projectMemberlist = [];
      this.projectPartnerlist = [];
      this.upDataPaperList();
    } else {
      this.seePaperItemOff = true;
      this.upDataPaperList(target.params.id);
    }
    next();
  },
  watch: {
    globalUploadStatus() {
      this.upDataPaperList(this.$route.params.id);
    }
  },
  computed: {
    creatPaperPower() {
      return [
        this.seePaperItemOff
          ? {
              label: "项目成员",
              organize: this.projectName,
              value: "1"
            }
          : {
              label: "向团队公开",
              organize: "Hiten",
              value: "1"
            },
        {
          label: "非公开",
          annotation: "仅自己和被邀请的人可查看并编辑",
          value: "0"
        }
      ];
    },
    labelInfo() {
      return this.seePaperItemOff
        ? {
            createBtnText: "新Paper",
            url: "/dis/p/new",
            update: "/dis/p/get"
          }
        : {
            createBtnText: "创建项目",
            url: "/dis/project/add",
            update: "/dis/project/list"
          };
    },
    ...mapGetters(["xppUserInfo", "xppProjectMemberInfo", "globalUploadStatus"])
  },
  data() {
    return {
      projectFounderStatus: false,
      activeName: "first",
      dialogCreatePaperVisible: false,
      createNewPaperLoad: false,
      seePaperItemOff: !!this.$route.params.id,
      breadcrumb: [
        {
          id: "1154244380135051265",
          tit: "Hiten",
          to: "/"
        }
      ],
      partakeProjectState: 1,
      partakeProjectInfo: {},
      projectPaperStateInfo: {},
      partakeProjectStateSelect: [
        {
          value: 1,
          label: "已加入"
        },
        {
          value: 0,
          label: "未加入"
        }
      ],
      formPaperData: {
        name: "",
        creatType: 1,
        openFlag: "1",
        standard: "1"
      },
      rule: {
        name: [
          {
            validator: (rule, value, callback) => {
              if (value.trim() === "") {
                this.formPaperData.name = "";
                return callback(new Error("请输入项目名称"));
              }
              callback();
            },
            trigger: "blur"
          }
        ]
      },
      paperList: [],
      cpaperTemplateSelect: [
        { id: 1, name: "工程项目" },
        { id: 2, name: "人事招聘" },
        { id: 3, name: "市场活动" },
        { id: 4, name: "产品开发" }
      ],
      projectMemberlist: [],
      projectPartnerlist: []
    };
  },
  mounted() {
    this.upDataPaperList(this.$route.params.id);
  },
  methods: {
    async upDataPaperList(id) {
      let params = this.seePaperItemOff
        ? { projectId: id }
        : { companyId: this.xppUserInfo.companyId };
      let response = await this.$axios.get(this.labelInfo.update, {
        params
      });
      if (this.seePaperItemOff) {
        let {
          companyName,
          projectName,
          list: paperList,
          createDate,
          updateDate,
          creatorInfo,
          permissionInfo
        } = response.info;
        // 是否为项目创始人
        if (creatorInfo.id === this.xppUserInfo.id) {
          this.projectFounderStatus = true;
        }
        this.companyName = response.info.companyName;
        this.projectName = response.info.projectName;
        this.$set(this.breadcrumb, 1, {
          id,
          tit: projectName || "未命名"
        });
        this.projectPaperStateInfo = {
          companyName,
          projectName,
          createDate,
          updateDate,
          ...creatorInfo
        };
        this.partakeProjectInfo = permissionInfo;
        this.partakeProjectState = permissionInfo.permissionFlag;
        let { memberlist, partnerlist } = await this.$GetResourceMemberList({
          resourceId: id,
          resourceType: "1",
          dup: true
        });
        this.projectMemberlist = memberlist;
        this.projectPartnerlist = partnerlist;

        this.$UpdataVuexState({
          key: "xppProjectMemberInfo",
          data: {
            status: false,
            title: this.projectName,
            projetcId: this.$route.params.id
          }
        });
        let itemMembersList = [...memberlist, ...partnerlist].filter(
          dd => dd.userId === this.xppUserInfo.id
        );
        let clist = paperList.map(item => {
          item.membersList = itemMembersList;
          item.creator = Vue.filter("teamInfoVail")(item.creator);
          return item;
        });
        this.paperList = this.$jok.rules(clist, this.xppUserInfo.id);
      } else {
        this.paperList = this.$jok.rules(response.list, this.xppUserInfo.id);
      }
    },
    resetForm() {
      this.$refs["formPaper"].resetFields();
    },
    createNewPaper() {
      let projectId = this.$route.params.id;
      this.$refs["formPaper"].validate(async valid => {
        if (!valid) {
          return console.log("error submit!!");
        }
        this.createNewPaperLoad = true;
        let newPaperId = null;
        let params = null;
        if (this.seePaperItemOff) {
          let width, height;
          if (this.formPaperData.standard === "1") {
            (width = "3300"), (height = "1856");
          } else {
            (width = "2310"), (height = "3387");
          }
          newPaperId = await this.$axios.get("/dis/p/id");
          params = formParamsFormat({
            projectId,
            paperId: newPaperId.info.id,
            width,
            height,
            paperName: this.formPaperData.name,
            openFlag: this.formPaperData.openFlag
          });
        } else {
          params = formParamsFormat({
            companyId: this.xppUserInfo.companyId,
            projectName: this.formPaperData.name,
            openFlag: this.formPaperData.openFlag
          });
        }
        await this.$axios.post(this.labelInfo.url, params);
        await this.upDataPaperList(projectId || null);
        this.dialogCreatePaperVisible = false;
        this.createNewPaperLoad = false;
        this.formPaperData.name = "";
        if (this.seePaperItemOff) {
          window.open(`/paper/${newPaperId.info.id}`, "_blank", "noopener");
          // this.$router.push({
          //   name: "Paper",
          //   params: {
          //     id: newPaperId.info.id
          //   }
          // });
        } else {
          this.$router.push({
            name: "HomeProjectPaper",
            params: {
              id: this.paperList.slice(-1)[0].id
            }
          });
        }
      });
    },
    async changeProjectPartakeState(state) {
      let postsrc = "";
      let params = {
        companyId: this.xppUserInfo.companyId
      };
      if (state) {
        postsrc = "/dis/m/addmember";
        params.resourceId = this.$route.params.id;
        params.resourceType = 1;
      } else {
        postsrc = "/per/m/out";
        params.resourceId = this.$route.params.id;
      }
      let { status } = await this.$axios.post(
        postsrc,
        formParamsFormat(params)
      );
      if (status !== 200) return;
      if (state) {
        this.partakeProjectState = 1;
      }
    },
    async openMemberDialog() {
      this.$UpdataVuexState({
        key: "xppProjectMemberInfo",
        data: { status: true }
      });
    },
    openInvitationDialog(type) {
      let {
        projetcId: id,
        title,
        memberlist,
        partnerlist
      } = this.xppProjectMemberInfo;
      this.$DialogInvitationToJoinShow({
        id,
        type,
        title,
        member: type === 1 ? memberlist : partnerlist,
        visible: true,
        resourceType: "1"
      });
    },
    openCreatePaperDialog() {
      this.dialogCreatePaperVisible = true;
      this.$nextTick(() => {
        this.$refs.titleEditInput.focus();
      });
    },
    changePaperCreatType({ target }) {
      let active = target.parentElement.getElementsByClassName(
        "state-active"
      )[0];
      active.classList.remove("state-active");
      target.classList.add("state-active");
      this.formPaperData.creatType = target.dataset.type - 0;
      if (this.formPaperData.creatType === 1) {
        this.formPaperData.standard = "1";
      } else {
        this.formPaperData.standard = "3";
      }
    },
    ...mapMutations(["$UpdataVuexState", "$DialogInvitationToJoinShow"]),
    ...mapActions(["$GetResourceMemberList"])
  }
};
</script>

<style lang="scss" scoped>
.home-index-page {
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  height: calc(100vh - 76px);
  display: flex;
}
.home-index-content-box {
  position: relative;
  flex: auto;
}
.home-index-information {
  flex: none;
  padding-top: 32px;
  width: 252px;
  padding: 0 20px 100px 20px;
  max-height: 100%;
  overflow: auto;
}
.home-index-con {
  margin-top: 32px;
  height: calc(100vh - 140px) !important;
  overflow: auto;
  padding-bottom: 0 !important;
}

h2 {
  height: 32px;
  line-height: 32px;
  justify-content: flex-start;

  .breadcrumb-delimiter {
    flex: none;
    height: 100%;
    width: 0;
    border-right: 1px solid #ddd;
    margin: 0 12px;
  }

  span {
    flex: none;
    height: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: inherit;
    text-rendering: optimizeSpeed;
  }
}

.xpp-power-select {
  width: 100%;
  .el-input__inner {
    height: 60px;
    div:last-child {
      &::after {
        content: "";
      }
    }
  }
}

.xpp-partake-select {
  width: 120px;
}

.home-index-paper-info {
  text-align: center;
  padding: 22px;
  border-radius: 4px;
  background-color: #fcfcfc;
  border: 1px solid #e4e4e4;
  line-height: 1.8;
  margin-bottom: 20px;
  h3 {
    font-size: 14px;
    color: #373839;
    font-weight: normal;
  }
  p {
    font-size: 13px;
    color: #8e959a;
  }
  .el-button {
    margin-top: 5px;
    background-color: #58ce81;
    border-color: #58ce81;
  }
}
.xpp-index-information-item {
  margin-bottom: 20px;
}
.xpp-index-information-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  span {
    font-size: 13px;
    color: #373839;
    line-height: 20px;
    font-weight: 600;
  }
  .el-button {
    font-size: 12px;
    color: #f5a623;
    padding: 0;
    height: 20px;
    line-height: 20px;
    &:hover {
      color: #e49a22;
    }
  }
}
.xpp-index-information-content {
  font-size: 13px;
  color: #8e959a;
  line-height: 1.6;
}
.xpp-partake-content {
  .xpp-partake-state {
    width: 120px;
    border: 1px solid #9ba1a5;
    border-radius: 4px;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
  }
  p {
    font-size: 13px;
    color: #8e959a;
    margin-top: 0.7em;
  }
}
.xpp-project-members-list {
  flex-wrap: wrap;
  align-content: flex-start;
}

.xpp-project-members-add,
.xpp-project-members-item {
  flex: none;
  width: 28px;
  height: 28px;
  margin-bottom: 8px;
  margin-right: 8px;
}
.xpp-project-members-add {
  border: 1px dashed #abb2b8;
  color: #abb2b8;
  text-align: center;
  line-height: 28px;
  border-radius: 100%;
  cursor: pointer;
  &:hover {
    border-color: transparent;
    color: #fff;
    background-color: #f5a623;
  }
}
.xpp-project-members-item {
  position: relative;
  &:nth-child(7) {
    margin-right: 0;
  }
  div {
    width: 100%;
    height: 100%;
  }
}
.paper-creat-config-option {
  padding-top: 47px;
}
.paper-creat-standard {
  position: relative;
  p {
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #333333;
  }
  margin-right: 30px;
}
.paper-cs-bg {
  width: 90px;
  height: 90px;
  margin-bottom: 7px;
  transition: color 0.2s;
  transition: background-color 0.2s;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    &::before {
      content: "";
      background-color: #f4f4f4;
      border-radius: 4px;
      box-shadow: inset 0 0 0 1px #f4f4f4;
    }
  }
}

.paper-cs-type1 {
  &::before {
    width: 90px;
    height: 66px;
  }
}
.paper-cs-type2 {
  &::before {
    width: 64px;
    height: 90px;
  }
}

.paper-cs-radio-input {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  margin: 0;
  cursor: pointer;
  &:checked {
    & + div {
      div {
        &::before {
          background-color: #f1f7fc;
          box-shadow: inset 0 0 0 1px #4a90e2;
        }
      }
    }
  }
}
.paper-creat-config-tit {
  display: flex;
  border-bottom: 3px solid #f4f4f4;
  width: 335px;
  span {
    flex: none;
    cursor: pointer;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
    padding-bottom: 6px;
    margin-bottom: -3px;
    &:first-child {
      margin-right: 79px;
    }
    &.state-active {
      color: #f5a623;
      border-bottom: 3px solid #f5a623;
    }
  }
}
.cpaper-template-container {
  align-items: stretch;
  margin-bottom: 50px;
}
.cpaper-template-select {
  flex: auto;
  overflow: hidden;
  position: relative;
}
.cpaper-template-selectbox {
  transition: margin 0.2s;
}
.cpaper-template-selectitem {
  flex: none;
  width: 160px;
  height: 130px;
  padding: 15px 13px 0;
  text-align: center;
  position: relative;
  &:first-child {
    padding-left: 6px;
  }
  div {
    height: 90px;
    box-shadow: inset 0 0 0 1px #f4f4f4;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: 158px 88px;
    background-position: center;
  }
  p {
    font-size: 12px;
    margin-top: 19px;
    height: 20px;
    line-height: 20px;
    color: #333333;
  }
  input {
    position: absolute;
    margin: 0;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
.cpaper-template-cbtn {
  flex: none;
  width: 24px;
  font-size: 24px;
  color: #c2c6d1;
  cursor: pointer;
  i {
    color: inherit;
    font-weight: bold;
    margin-top: -20px;
  }
  &:hover {
    color: #f5a623;
  }
}

[data-template="1"] {
  background-image: url(/img/canvas_thumb/1.png);
  &.templ-active {
    background-image: url(/img/canvas_thumb/8.png);
  }
}
[data-template="2"] {
  background-image: url(/img/canvas_thumb/2.png);
  &.templ-active {
    background-image: url(/img/canvas_thumb/7.png);
  }
}
[data-template="3"] {
  background-image: url(/img/canvas_thumb/3.png);
  &.templ-active {
    background-image: url(/img/canvas_thumb/6.png);
  }
}
[data-template="4"] {
  background-image: url(/img/canvas_thumb/4.png);
  &.templ-active {
    background-image: url(/img/canvas_thumb/5.png);
  }
}
</style>

<style lang="scss">
.xpp-creat-power-option {
  .el-select-dropdown__item {
    padding: 5px 34px 5px 2.3em;
    height: auto;
    &::before {
      background: url(/img/dropdown_selected_ic_1.svg) center/100% no-repeat;
      width: 12px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 5px;
      line-height: inherit;
      text-align: center;
      margin: 0.7em 0.8em;
    }
    &.selected::before {
      content: "";
    }
    p {
      color: #373839;
    }
    p:first-child {
      font-weight: 700;
    }
    p:last-child {
      font-size: 0.9em;
      opacity: 0.6;
      font-weight: normal;
    }
  }
}
.xpp-partake-select {
  &.xpp-partake-joined {
    input {
      background-color: #58ce81;
      border-color: transparent;
    }
    input,
    .el-select__caret {
      color: #fff;
    }
  }
  input {
    text-align: center;
    background-color: #ebeef2;
    border-color: #c2c6d1;
  }
  .xpp-partake-select-item {
    text-align: center;
  }
}
.xpp-creat-paper-dialog {
  border-radius: 10px;
  .el-dialog__header {
    padding: 30px;
    padding-bottom: 0;
  }
  .el-dialog__title {
    padding: 0;
    font-weight: bold;
  }
  .el-dialog__body {
    padding: 30px;
  }
}
</style>
