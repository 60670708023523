<template>
  <el-dialog
    :visible.sync="xppProjectMemberInfo.status"
    :append-to-body="true"
    :close-on-click-modal="false"
    custom-class="xpp-project-member-management"
    width="640px"
  >
    <div slot="title">
      <h2>项目成员</h2>
      <h3>
        <i class="iconfont icon-folder"></i>{{ xppProjectMemberInfo.title }}
      </h3>
    </div>
    <div>
      <div class="xpp-projectinfo-title">
        <span class="text-overflow">团队成员</span
        ><span class="xpp-projectinvite-btn" @click="invitationToJoinShow(1)"
          >邀请团队成员</span
        >
      </div>
      <ul class="xpp-project-memberlist xpp-scrollbar">
        <li v-for="item in xppProjectMemberInfo.memberlist" :key="item.userId">
          <div
            class="xpp-user-photo"
            :style="item.headImg | styleUserPhoto"
          ></div>
          <div class="xpp-member-info-container">
            <b>{{ item.nickName }}</b>
            <b v-if="item.userId === xppUserInfo.id">（我）</b>
            <template v-if="item.position">
              <br />
              <span>{{ item.position }}</span>
            </template>
          </div>
          <div class="xpp-user-role-container">
            <span class="role-admin" v-if="item.roleId === 4">所有者</span>
            <span class="role-manager" v-if="item.roleId === 6">管理员</span>
          </div>
          <div
            class="xpp-project-memberadmin-btn"
            v-if="
              xppProjectMemberInfo.observerAuthority &&
                item.userId !== xppUserInfo.id
            "
          >
            <i class="el-icon-more"></i>
            <el-select
              v-model="item.roleId"
              @change="projectOptionsChange(true, item)"
              popper-class="xpp-project-memberadmin-select"
            >
              <el-option :value="item.roleId === 6 ? 5 : 6">
                <i class="iconfont"></i>
                <span>{{ item.roleId === 6 ? "设为成员" : "设为管理员" }}</span>
              </el-option>
              <el-option :value="0">
                <i class="iconfont"></i>
                <span>移除此成员</span>
              </el-option>
            </el-select>
          </div>
        </li>
      </ul>
    </div>
    <div>
      <div class="xpp-projectinfo-title">
        <span class="text-overflow">外部合作者</span
        ><span class="xpp-projectinvite-btn" @click="invitationToJoinShow(2)"
          >邀请外部合作者</span
        >
      </div>
      <ul class="xpp-project-memberlist xpp-scrollbar">
        <li v-for="item in xppProjectMemberInfo.partnerlist" :key="item.userId">
          <div
            class="xpp-user-photo"
            :style="item.headImg | styleUserPhoto"
          ></div>
          <div class="xpp-member-info-container">
            <b>{{ item.nickName }}</b>
            <template v-if="item.position">
              <br />
              <span>{{ item.position }}</span>
            </template>
          </div>
          <div class="xpp-user-role-container">
            <span class="role-admin" v-if="item.roleId === 1">所有者</span>
            <span class="role-manager" v-if="item.roleId === 3">管理员</span>
          </div>
          <div
            class="xpp-project-memberadmin-btn"
            v-if="
              xppProjectMemberInfo.observerAuthority &&
                item.userId !== xppUserInfo.id
            "
          >
            <i class="el-icon-more"></i>
            <el-select
              v-model="item.roleId"
              @change="projectOptionsChange(false, item)"
              popper-class="xpp-project-memberadmin-select"
            >
              <el-option :value="item.roleId === 6 ? 5 : 6">
                <i class="iconfont"></i>
                <span>{{ item.roleId === 6 ? "设为成员" : "设为管理员" }}</span>
              </el-option>
              <el-option :value="0">
                <i class="iconfont"></i>
                <span>移除此成员</span>
              </el-option>
            </el-select>
          </div>
        </li>
        <li
          class="xpp-member-unmanned"
          data-label="无合作者"
          v-if="!xppProjectMemberInfo.partnerlist.length"
        ></li>
      </ul>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "ProjectMemberManagement",
  computed: {
    ...mapGetters(["xppUserInfo", "xppProjectMemberInfo"])
  },
  data() {
    return {
      projectOptions: [
        { value: "6", label: "设为管理员" },
        { value: "2", label: "移除此成员" }
      ]
    };
  },
  methods: {
    async projectOptionsChange(type, val) {
      let list = type
        ? this.xppProjectMemberInfo.memberlist
        : this.xppProjectMemberInfo.partnerlist;
      if (val.roleId === 0) {
        let params = new FormData();
        params.append("selectUserId", val.userId);
        params.append("resourceId", val.resourceId);
        await this.$axios.post("/per/m/remove", params);
        for (let i = 0; i < list.length; i++) {
          if (list[i].userId === val.userId) {
            list.splice(i, 1);
          }
        }
      } else {
        let params = new FormData();
        params.append("id	", val.id);
        params.append("roleId", val.roleId);
        await this.$axios.post("/per/m/update", params);
      }
      let params = {
        key: "xppProjectMemberInfo",
        data: { status: false }
      };
      params.data[type ? "memberlist" : "partnerlist"] = list;
      this.$UpdataVuexState(params);
    },
    invitationToJoinShow(type) {
      let { projetcId: id, title } = this.xppProjectMemberInfo;
      this.$DialogInvitationToJoinShow({
        id,
        type,
        title,
        visible: true,
        resourceType: "1"
      });
    },
    ...mapMutations(["$UpdataVuexState", "$DialogInvitationToJoinShow"])
  }
};
</script>
<style lang="scss" scoped>
.xpp-project-member-management {
  h2,
  h3 {
    font-weight: 600;
    font-size: 16px;
  }
  h2 {
    margin-bottom: 0.8em;
  }
  .icon-folder {
    font-size: 20px;
    color: #f7bc03;
    margin-right: 8px;
  }
}
.xpp-projectinfo-title {
  display: flex;
  padding-bottom: 6px;
  margin-bottom: 6px;
  box-shadow: inset 0 -1px 0 0 #eee;
  .text-overflow {
    flex: auto;
    color: #8e959a;
  }
  .xpp-projectinvite-btn {
    flex: none;
    color: #cf8d1f;
    cursor: pointer;
  }
}
.xpp-project-memberlist {
  min-height: 135px;
  max-height: 305px;
  margin-bottom: 30px;
  &::-webkit-scrollbar {
    width: 11px;
  }
  li {
    padding: 5px;
    display: flex;
    align-items: center;
    font-size: 0;
  }
  .xpp-user-photo {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background-color: #999;
  }
  b {
    font-weight: 600;
    font-size: 13px;
    color: #373839;
    line-height: 16px;
  }
  span {
    font-size: 12px;
    color: #8e959a;
    line-height: 16px;
  }
}
.xpp-user-role-container {
  flex: auto;
  span {
    display: inline-block;
    height: 19px;
    line-height: 19px;
    padding: 0 22px;
    border-radius: 3px;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    margin-left: 12px;
    border: 1px solid transparent;
    &.role-admin {
      background-color: #3db266;
    }
    &.role-manager {
      background-color: #ffbf76;
    }
  }
}
.xpp-member-unmanned {
  display: block !important;
  background: url(/img/modal-list-fallback.svg) center 10px/65px no-repeat;
  width: 100%;
  text-align: center;
  padding-top: 75px !important;
  padding-bottom: 15px !important;
  &::before {
    content: attr(data-label);
    font-size: 12px;
    color: #757575;
    line-height: 15px;
  }
}
.xpp-project-memberadmin-btn {
  margin-right: 15px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  position: relative;
  i {
    pointer-events: none;
  }
  .el-select {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
  }
}
::v-deep {
  .xpp-project-memberadmin-btn {
    .el-input__inner {
      padding: 0;
      height: 100%;
    }
    .el-input__suffix {
      display: none;
    }
  }
  .xpp-project-memberadmin-select {
    width: 140px;
    margin-left: -28px;
  }
}
</style>
