<template>
  <div class="home-favorites-page">
    <h2 class="home-favorites-tit">收到的paper</h2>
    <div class="favorites-menu">
      <el-tabs
        class="xpp-tabs-con"
        v-model="classType"
        @tab-click="handleClick"
      >
        <el-tab-pane label="全部" name="1"></el-tab-pane>
        <el-tab-pane label="项目" name="4"></el-tab-pane>
        <el-tab-pane label="paper" name="5"></el-tab-pane>
      </el-tabs>
      <div class="favorites-sort">
        <div class="f-select">
          <el-select v-model="sortValue" placeholder="请选择" @change="filter">
            <el-option
              v-for="item in sortOptions"
              :key="item.calss"
              :label="item.text"
              :value="item.calss"
            ></el-option>
          </el-select>
        </div>
        <div class="f-icon" @click="tabSort('list')">
          <i
            class="el-icon-s-fold"
            :class="rowOrCell === 'list' ? '' : 'f-none-color'"
          ></i>
        </div>
        <div class="f-icon" @click="tabSort('block')">
          <i
            class="el-icon-menu"
            :class="rowOrCell === 'list' ? 'f-none-color' : ''"
          ></i>
        </div>
      </div>
    </div>
    <open
      :value="tmeplateObj"
      :menulist="menu"
      v-if="isright.type"
      :x="x"
      :y="y"
      class="index"
      @listionEvent="listionEvent"
    ></open>
    <div
      class="list-row home-list xpp-scrollbar"
      v-if="sort != -2 && sort != 2"
    >
      <!-- 横排 -->
      <div v-for="(item, index) in filterList" :key="index">
        <div class="row-list-span-title" v-if="sort == 1 || sort == -1">
          {{ item.updateDate }}
        </div>
        <div class="row-list-span-title" v-if="sort == 3 || sort == -3">
          <div
            class="xpp-user-photo"
            :style="item.headImg | styleUserPhoto"
          ></div>
          <span>{{ item.nickName }}</span>
        </div>
        <div class="row-list-span-title" v-if="sort == 5 || sort == -5">
          <i
            class="iconfont icon-folder"
            :style="{ color: item.colour, fontSize: '22px' }"
          ></i>
        </div>
        <div
          class="home-project-list"
          @contextmenu.prevent="show($event)"
          v-if="rowOrCell === 'block'"
        >
          <div v-for="item in item.option" :key="item.id">
            <projectRow
              :data="item"
              @listionEvent="listionEvent"
              v-if="item.soruceType == 1"
            ></projectRow>
            <paperRow
              :data="item"
              v-if="item.soruceType == 2"
              @listionEvent="listionEvent"
            ></paperRow>
          </div>
        </div>
        <!-- 竖排 -->
        <div @contextmenu.prevent="show($event)" v-else>
          <div v-for="item in item.option" :key="item.id">
            <projectCell
              :data="item"
              @listionEvent="listionEvent"
              v-if="item.soruceType == 1"
            ></projectCell>
            <paperCell
              :data="item"
              @listionEvent="listionEvent"
              v-if="item.soruceType == 2"
            ></paperCell>
          </div>
        </div>
      </div>
    </div>
    <div
      class="list-row home-list xpp-scrollbar"
      v-if="sort == 2 || sort == -2 || sort == 4 || sort == -4"
    >
      <!-- 横排 -->
      <div
        class="home-project-list"
        @contextmenu.prevent="show($event)"
        v-if="rowOrCell === 'block'"
      >
        <div v-for="item in filterList" :key="item.id">
          <projectRow
            :data="item"
            @listionEvent="listionEvent"
            v-if="item.soruceType == 1"
          ></projectRow>
          <paperRow
            :data="item"
            v-if="item.soruceType == 2"
            @listionEvent="listionEvent"
          ></paperRow>
        </div>
      </div>
      <!-- 竖排 -->
      <div @contextmenu.prevent="show($event)" v-else>
        <div v-for="item in filterList" :key="item.id">
          <projectCell
            :data="item"
            @listionEvent="listionEvent"
            v-if="item.soruceType == 1"
          ></projectCell>
          <paperCell
            :data="item"
            @listionEvent="listionEvent"
            v-if="item.soruceType == 2"
          ></paperCell>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import open from "@components/HomerightList";
import { mapGetters, mapMutations } from "vuex";
import paperRow from "@components/view/paperRow";
import paperCell from "@components/view/paperCell";
import projectRow from "@/components/view/projectRow";
import projectCell from "@/components/view/projectCell";
export default {
  name: "HomeFavorites",
  computed: {
    ...mapGetters(["xppCompanyId", "xppUserInfo", "rowOrCell"])
  },
  data() {
    return {
      menu: [],
      list: [],
      x: 0,
      y: 0,
      classType: "1",
      listArray: [],
      filterList: [],
      isright: {
        type: false
      },
      tmeplateObj: {},
      sortOptions: [
        {
          calss: "1",
          text: "最后修改日期"
        },
        {
          calss: "2",
          text: "名称"
        },
        {
          calss: "3",
          text: "所有者"
        },
        {
          calss: "5",
          text: "标签"
        }
      ],
      // 下拉列表选中值
      sortValue: "1",
      sort: 1
      // 菜单格式选中值 list or block
    };
  },
  components: {
    open,
    paperCell,
    paperRow,
    projectCell,
    projectRow
  },
  mounted() {
    this.getlist();
  },
  methods: {
    handleClick(e) {
      this.sort = e.name;
      this.filterList = [
        ...this.$jok.sortFilter([...this.list], {
          class: e.name,
          sort: this.sort
        })
      ];
    },
    filter(v) {
      //为了不改变原数据 新加一个 filterList data数据在上放
      let sort = Math.abs(v);
      this.filterList = [
        ...this.$jok.sortFilter(this.list, {
          class: this.classType,
          sort: sort
        })
      ];
      this.sort = sort;
    },
    getlist() {
      this.$axios.get("/dis/p/receivelist").then(res => {
        let list = res.list.map(item => {
          if (item.paper) {
            item.paper.soruceType = 2;
            item.paper.membersList = item.membersList;
            return item.paper;
          }
        });
        this.list = this.$jok.rules(list, this.xppUserInfo.id);
        this.filterList = this.$jok.sortFilter(this.list, {
          class: this.classType,
          sort: this.sort
        });
      });
    },
    listionEvent(e) {
      switch (e.event) {
        case "soucang":
        case "store":
          this.stars(e.value);
          break;
        case "mouseenter":
          this.tmeplateObj = e.value;
          break;
        case "set":
          this.tmeplateObj = e.value;
          this.show(e.e, true);
          break;
      }
    },
    show(ev) {
      this.isright.type = false;
      this.menu = [];
      let userRoleId = 4;
      if (this.tmeplateObj.projectId) {
        switch (userRoleId) {
          case 4:
            this.menu = [4, 0, 5, 2, 10, 11, 12, 6];
            break;
          case 5:
          case 6:
          case 7:
            this.menu = [0, 5, 2, 10, 11];
            break;
          case false:
            this.menu = [];
            return;
        }
      } else {
        switch (userRoleId) {
          case 4:
            this.menu = [4, 5, 0, 1, 2, 6];
            break;
          case 6:
            this.menu = [4, 5, 0, 1, 2, 3];
            break;
          case 5:
          case 7:
            this.menu = [0, 1, 2, 3];
            break;
          case false:
            this.menu = [];
            return;
        }
      }

      if (window.openmenu) {
        window.openmenu.type = false;
      }
      this.$nextTick(() => {
        this.$set(this.isright, "type", true);
        window.openmenu = this.isright;
      });
      this.y = ev.y + "px";
      this.x = ev.x + "px";
    },
    sliceLength(val) {
      if (val instanceof Array) {
        return val.slice(0, 4);
      } else {
        return [];
      }
    },
    //右键的回调函数
    then() {
      //data 回调回来的数据
    },
    stars(obj) {
      if (obj.collectionFlag == 1) {
        obj.collectionFlag = 0;
      } else {
        obj.collectionFlag = 1;
      }
      let data = {
        companyId: this.xppUserInfo.companyId,
        sourceId: obj.id,
        sourceType: obj.soruceType,
        collectionFlag: obj.collectionFlag
      };
      this.$axios.post("/dis/cl/collection", qs.stringify(data)).then(() => {
        if (obj.collectionFlag == 1) {
          this.$message("收藏成功");
        } else {
          this.$message("取消成功");
        }
      });
    },
    /**
     * @description: 排序形式
     * @param {string} para list or blockoqu
     */
    tabSort(para) {
      this.$tagRowOrCell(para);
    },
    seeProjectInfo({ id }) {
      this.$router.push({
        name: "HomeProjectPaper",
        params: {
          id
        }
      });
    },
    ...mapMutations(["$tagRowOrCell"])
  }
};
</script>

<style lang="scss" scoped>
.list {
  margin-top: 10px;
}
.icon-shoucang1,
.icon-favorites-icon {
  font-size: 12px;
}
.home-favorites-page {
  height: 100%;
  padding: 26px 32px;
}
.home-project-item-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.home-project-item-list-title {
  min-height: 1px;
  font-weight: 700;
  margin-top: 0;
  margin-left: -10px;
  display: block;
  background-color: #fff;
  top: 0;
  z-index: 100;
  padding-bottom: 10px;
  padding-top: 14px;
}
.home-favorites-tit {
  margin-bottom: 16px;
}
.stars {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.stars img {
  width: 100%;
  height: 100%;
}
.favorites-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .favorites-sort {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 220px;
    height: 40px;
    .f-select {
      ::v-deep .el-input__inner {
        border: none;
        width: 150px;
        text-align: right;
      }
    }
    .f-icon {
      cursor: pointer;
      i {
        font-size: 20px;
      }
      ::v-deep .f-none-color {
        color: #ddd;
      }
    }
  }
}

.home-project-list {
  // height: calc(100vh - 200px);
  padding-top: 0px;
  overflow: scroll;
  .home-project-item,
  .home-item-page {
    flex: none;
    margin-bottom: 32px;
    margin-right: 16px;
    // min-width: 200px;
    width: 280px;
    // width: calc(20% - 16px);
  }
}
.home-item-page {
  .canvas-item-in-container {
    background-color: #fbfbfc;
    border: 1px solid #e2e2e2;
    box-shadow: 0 1px 5px rgba(50, 50, 50, 0.1);
    border-radius: 6px;
    margin-top: 3px;
    .canvas-item-thumbnail {
      position: relative;
      padding: 16px;
      border-bottom: 1px solid #e2e2e2;
      box-sizing: border-box;
      padding-bottom: 0;
      .item-head {
        position: absolute;
        right: 0;
        span {
          width: 24px;
          height: 24px;
          z-index: 30;
          padding: 0;
          background-color: #fff;
          border-radius: 5px;
          border: 1px solid #e6e6e6;
          display: inline-block;
          text-align: center;
          line-height: 24px;
          margin-right: 5px;
          i {
            font-size: 18px;
          }
        }
      }
      .item-info {
        background-color: #fff;
        background-position: top center;
        background-size: cover;
        transition: border-color 0.2s;
        pointer-events: none;
        overflow: hidden;
        border-radius: 6px 6px 0 0;
        height: 82px;
      }
    }
    .canvas-item-info-container {
      padding: 9px 13px;
      background-color: #fff;
      border-radius: 6px;
      .canvas-title {
        font-size: 15px;
        font-weight: 500;
        color: #373839;
        line-height: 24px;
        display: flex;
        align-items: center;
        height: 24px;
        overflow: hidden;
        span {
          white-space: nowrap;

          overflow: hidden;
          text-overflow: ellipsis;
        }
        .hover-img {
          cursor: pointer;
        }
        img {
          width: 40px;
          height: 40px;
        }
        &:hover {
          .hover-img {
            display: inline-block;
          }
        }
      }
      .canvas-updata-time {
        font-size: 12px;
        font-weight: 500;
        color: #8e959a;
        max-width: 180px;
        display: inline-block;
        vertical-align: middle;
      }
      .canvas-name {
        display: flex;
        align-items: center;
        padding-top: 10px;
        .head-img {
          width: 24px;
          height: 24px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 8px;
          border: 1px solid #ccc;
        }
        .head-name {
          font-size: 13px;
          font-weight: 400;
          color: #8e959a;
        }
      }
    }
  }
}
.paperTitle {
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  width: 100%;
  background-color: transparent;
  top: -3px;
}
.paperTitle p {
  display: inline-block;
  line-height: 18px;
  font-size: 13px;
  margin-left: 6px;
  color: #8e959a;
  max-width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fol {
  width: 16px;
  height: 16px;
}
.list-row {
  width: calc(100% - 1px);
  height: calc(100vh - 200px);
  overflow: scroll;
  padding: 0 1px;
  .list-row-title {
    position: sticky;
    min-height: 1px;
    font-weight: 700;
    display: block;
    background-color: #fff;
    box-shadow: 3px 0 0 0 #fff;
    z-index: 100;
    padding-bottom: 10px;
    top: -5px;
    margin-bottom: 4px;
    font-size: 12px;
    color: #777;
  }
}
.list-row-title1 {
  position: sticky;
  width: 98%;
  font-weight: 700;
  background-color: #fff;
  z-index: 100;
  top: -5px;
  padding: 4px 0;
  font-size: 12px;
  color: #777;
}
.home-list {
  position: relative;
  > ul .home-list-li {
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px #e2e2e2;
    background-color: #fff;
    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .list-left {
    display: flex;
    align-items: center;
    &.left-no-img {
      margin-left: 20px;
    }
    .icon-folder {
      color: #f7bc03;
      font-size: 28px;
    }
    .list-left-name {
      padding-left: 20px;
      font-size: 14px;
      color: #373839;
      .list-left-n {
        font-size: 15px;
        font-weight: 500;
        color: #373839;
        // line-height: 24px;
        position: relative;
        top: 2px;
        display: flex;
        align-items: center;
        .list-left-open {
          font-size: 11px;
          color: #484d57;
          margin-left: 5px;
          letter-spacing: 0.2px;
        }
        img {
          width: 30px;
          height: 30px;
          display: inline-block;
        }
      }
      .list-left-edit {
        color: #8e959a;
        font-size: 13px;
        position: relative;
        top: 3px;
      }
    }
    .list-left-img {
      width: 100px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fbfbfc;
      border-right: 1px solid #e2e2e2;
      img {
        width: 75px;
        height: 56px;
      }
    }
  }

  .list-right {
    display: flex;
    align-items: center;
    > ul {
      display: flex;
      li {
        height: 26px;
        width: 26px;
        border: 1px solid #abb2b8;
        text-align: center;
        line-height: 26px;
        font-size: 12px;
        border-radius: 50%;
        margin-left: 10px;
      }
      .right-bg {
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        width: 26px;
        background-image: url(/img/profile_default.png);
        overflow: hidden;
      }
    }
    .right-more {
      width: 24px;
      height: 24px;
      z-index: 30;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #e6e6e6;
      display: inline-block;
      text-align: center;
      line-height: 24px;
      .right-icon {
        font-size: 18px;
      }
    }
  }
}
</style>
